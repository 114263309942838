import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

function DocumentModal({ isOpen, onClose, response }) {
  return (
    <div
      className={`fixed z-50 inset-0 overflow-y-auto ${
        isOpen ? "block" : "hidden"
      }`}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-full max-h-full overflow-auto">
          <div className="flex justify-end mb-4">
            <button
              className="text-gray-600 hover:text-gray-800"
              onClick={onClose}
            >
              <Icon icon="lets-icons:back" className="w-6 h-6" />
            </button>
          </div>
          <div>
            <div className="w-full">
              {response && (
                <div dangerouslySetInnerHTML={{ __html: response }}></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocumentModal;
