import ChatAnswer from "../Components/ChatAnswer";
import { useEffect, useRef, useState } from "react";
import SpinnerLoading from "../Components/SpinnerLoading";
import Sidebar from "../Components/Sidebar";
import { useParams } from "react-router-dom";
import ChatLoader from "../Components/ChatLoader";
import ChatBox from "../Components/ChatBox";
import { useAppDispatch, useAppSelector } from "../store";
import { clearChatQueries, getChatsByChatId } from "../store/finChat";
import LinkUrl from "../Components/LinkUrl";
import useAuth from "../utils/useAuth";
import DocumentModal from "../Components/DocumentModal";
import DashboardSidebar from "./DashboardSidebar";

function AnswerSearch({ closeSideBar }) {
  const { user } = useAuth();
  const params = useParams();
  const dispatch = useAppDispatch();
  const lastNodeRef = useRef();
  const id = params.id;
  const { chatQueries, status } = useAppSelector(
    (state) => ({
      chatQueries: state?.finChat?.chatQueries,
      tickerToggle: state?.toggle?.ticker,
      toggleState: state.toggle.toggle,
      status: state?.finChat?.chatStatus,
    })
  );
  const [scrollId, setScrollId] = useState();
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [selectedOption, setSelectedOption] = useState(["auto"]);
  const [ticker, setTicker] = useState("");
  const [worker, setWorker] = useState("");
  const [url, setUrl] = useState("");
  const [mainContext, setMainContext] = useState("");
  const [response, setResponse] = useState(null);
  const [isFetched, setIsFetched] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);

  const handleOpenDocumentModal = () => {
    setIsDocumentModalOpen(true);
  };

  const handleCloseDocumentModal = () => {
    setIsDocumentModalOpen(false);
  };

  const questions = chatQueries?.chat?.queries
    ?.slice(-5)
    ?.map((answer) => answer.query);

  // const workers = chatQueries?.chat?.queries
  //   .map((answer) => answer?.answer?.answers?.map((answer) => answer?.worker))
  //   .reduce((acc, val) => acc.concat(val), []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const queries = chatQueries?.chat?.queries;
      if (queries && queries.length > 0) {
        const lastQuery = queries[queries.length - 1];
        const answer = lastQuery?.answer;
        if (answer) {
          const workerValue = answer?.answers?.[0]?.worker;
          if (
            workerValue === "10q" ||
            workerValue === "10k" ||
            workerValue === "Transcript"
          ) {
            const filteredAnswer = answer.answers.filter(
              (item) => item.worker === workerValue
            );
            setWorker(workerValue);
            if (filteredAnswer.length > 1) {
              const uniqueSources = [
                ...new Set(filteredAnswer.map((item) => item.source[0])),
              ];
              let concatenatedMainContext = "";
              uniqueSources.forEach((source) => {
                const sourceAnswers = filteredAnswer.filter(
                  (item) => item.source[0] === source
                );
                if (workerValue === "Transcript") {
                  const uniqContext = [
                    ...new Set(sourceAnswers.map((item) => item.mainContext)),
                  ];
                  uniqContext.forEach((item) => {
                    concatenatedMainContext += item + "---";
                  });
                } else {
                  concatenatedMainContext = sourceAnswers
                    .map((item) => item.mainContext)
                    .join("---");
                }
              });
              let mainContextArray = concatenatedMainContext.split("---");
              if (workerValue === "Transcript") {
                mainContextArray.pop();
              }
              setMainContext(mainContextArray);
            } else {
              setMainContext([filteredAnswer[0]?.mainContext]);
            }
            const cleanedUrl = filteredAnswer[0]?.source[0];
            setUrl(cleanedUrl);
          }
        }
      }
      setIsLoading(false);
    };

    fetchData();
  }, [chatQueries, question]);

  useEffect(() => {
    const getQueries = async () => {
      dispatch(getChatsByChatId(id));
    };
    if (user) {
      getQueries();
      setSelectedOption(["auto"]);
    }
  }, [user]);

  useEffect(() => {
    return () => {
      dispatch(clearChatQueries());
    };
  }, []);

  useEffect(() => {
    if (lastNodeRef.current) {
      lastNodeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatQueries, question]);


  return (
    <>
      {(status === "loading" || isLoading) && (
        <SpinnerLoading isLoading={true} />
      )}
      <div>
        <div className="flex md:flex-row flex-col">
          <DashboardSidebar />
          <Sidebar />
          <div
            className={`bg-backgroundColor header-heigth-1 flex justify-center items-center w-full`}
          >
            <div className="rounded-lg sm:w-full md:w-full lg:w-full">
              <div className="relative">
                <div className="header-heigth-1 overflow-y-auto lg:h-[100%] xl:h-[100vh] w-full">
                  {/* <div
                  className={`grid ${toggleState
                    ? !tickerToggle
                      ? "grid-cols-1"
                      : "grid-cols-1 xl:grid-cols-6"
                    : ""
                    } gap-4`}
                > */}
                  <div
                    className={`grid ${worker ? "grid-cols-1 xl:grid-cols-6" : "grid-cols-1"
                      } gap-4`}
                  >
                    <div
                      className={`col-span-1 lg:col-span-3 border-r-2 w-full border-borderColor header-height-1 overflow-y-scroll flex flex-col justify-between`}
                    >
                      <div
                        className={`${!chatQueries?.chat?.queries.length > 0
                          ? "header-height"
                          : ""
                          }`}
                      >
                        {chatQueries?.chat?.queries?.map((item, index, array) => {
                          const isLastIndex = index === array.length - 1;

                          return (
                            <div key={item?.messageId}>
                              <ChatAnswer
                                messageId={item?.messageId}
                                setIsChatLoading={setIsChatLoading}
                                setIsLoading={setIsLoading}
                                answerChat={item}
                                handleOpenDocumentModal={handleOpenDocumentModal}
                                threadId={id}
                                setQuestion={setQuestion}
                                selectedOption={selectedOption}
                                setTicker={setTicker}
                                setScrollId={setScrollId}
                                isLastIndex={isLastIndex}
                                closeSideBar={closeSideBar}
                              />
                            </div>
                          );
                        })}
                      </div>
                      {question && (
                        <div className="px-4 py-2 break-words mt-5 mb-8">
                          <p className="text-lg font-normal text-textPrimary">
                            {question}
                          </p>
                        </div>
                      )}
                      {isChatLoading && <ChatLoader />}
                      <div ref={lastNodeRef}></div>
                      <ChatBox
                        setIsChatLoading={setIsChatLoading}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        threadId={id}
                        setQuestion={setQuestion}
                        questions={questions}
                      />
                    </div>
                    {/* <div
                    className={`${toggleState
                      ? !tickerToggle
                        ? "hidden"
                        : "col-span-1 lg:col-span-3"
                      : "hidden"
                      } p-4 h-[100vh] w-full overflow-y-scroll `}
                  >
                    {chatQueries?.chat?.queries?.length > 0 ? (
                      workers[0] === "10q" || workers[0] === "10k" ? (
                        <Pinecone answerChat={chatQueries.chat.queries} />
                      ) : urlLink ? (
                        <LinkUrl urlLink={urlLink} />
                      ) : (
                        <AnalystData
                          answerChat={chatQueries.chat.queries}
                          ticker={ticker}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </div> */}
                    {worker && mainContext && url && (
                      <div className="col-span-1 lg:col-span-3 hidden xl:block p-4 header-height w-full overflow-y-scroll">
                        <LinkUrl
                          urlLink={url}
                          worker={worker}
                          mainContext={mainContext}
                          scrollId={scrollId}
                          response={response}
                          setResponse={setResponse}
                          isFetched={isFetched}
                          setIsFetched={setIsFetched}
                          setUrl={setUrl}
                        />
                      </div>
                    )}
                    {worker && mainContext && url && (
                      <DocumentModal
                        isOpen={isDocumentModalOpen}
                        onClose={handleCloseDocumentModal}
                        response={response}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AnswerSearch;
