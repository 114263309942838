import React from "react";
import Logo from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();

  return (
    <footer>
      <div className="w-full flex justify-center bg-[#F4F4F4]">
        <div className="w-[400px]">
          <div className="py-8 px-5">
            <div
              className="flex items-center justify-center cursor-pointer"
              onClick={() => navigate("/")}
            >
              <img src={Logo} alt="logo" className="h-8 w-8 mr-2" />
              <p className="text-2xl tracking-widest font-semibold">
                ASKFIN
              </p>
            </div>
            <div className="flex items-center justify-between mt-10">
              <p
                className="font-normal text-sm cursor-pointer text-textPrimary"
                onClick={() => navigate("/terms-of-service")}
              >
                Terms of Service
              </p>
              <p
                className="font-normal text-sm cursor-pointer text-textPrimary"
                onClick={() => navigate("/privacy-policy")}
              >
                Privacy Policy
              </p>
              <p
                className="font-normal text-sm cursor-pointer text-textPrimary"
                onClick={() => navigate("/contact")}
              >
                Contact
              </p>
            </div>
            <div>
              <p className="font-normal text-sm text-center mt-10">
                Copyright © 2020. LogoIpsum. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
