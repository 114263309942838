import { Icon } from "@iconify/react/dist/iconify.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store";
import { dashboardActiveTab } from "../store/sidebarSlice";
import { isFeatureLocked } from "../Components/Helper";

const sidebar = [
  {
    name: "Home",
    path: "/dashboard",
    icon: "heroicons:home",
    alwaysShow: true,
  },
  {
    name: "Threads",
    icon: "heroicons:chat-bubble-left-ellipsis",
    alwaysShow: true,
  },
  {
    name: "Starred",
    icon: "heroicons:star",
    alwaysShow: true,
  },
  {
    name: "DMs",
    icon: "heroicons:chat-bubble-left-right",
    alwaysShow: false,
    requireSubscription: true,
  },
  {
    name: "Space",
    icon: "heroicons:user-group",
    alwaysShow: false,
    requireSubscription: true,
  },
  {
    name: "Files",
    icon: "heroicons:document-duplicate",
    alwaysShow: true,
    requireSubscription: true,
  },
];

function DashboardSidebar() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dashboardActiveTabState, userDetailsState } = useAppSelector((state) => ({
    dashboardActiveTabState: state.sidebar.dashboardActiveTab,
    userDetailsState: state.auth.userDetails
  }));

  const handleNavigation = (item) => {
    if (isFeatureLocked(userDetailsState, item.name)) {
      console.log(`${item.name} is locked due to subscription status`);
      return;
    }

    dispatch(dashboardActiveTab(item.name));
    if (item.name === "Home") {
      navigate(item.path);
    }
    if (["Threads", "DMs", "Files", "Space", "Starred"].includes(item.name)) {
      navigate(`${location.pathname}`, {
        state: {
          isFirstTime: true
        }
      });
    }
  };

  const displaySidebar = sidebar.filter(item =>
    item.alwaysShow || userDetailsState?.usageType === "business"
  );

  return (
    <div className="dashboard-sidebar flex  md:flex-col justify-between header-height bg-[#0D2425] md:w-20 px-2 py-3 mypadding">
      <div className="dashboard-icon-container flex md:flex-col md:items-center">
        {displaySidebar.map((item, index) => (
          <div
            key={index}
            className={`relative flex w-full items-center justify-center rounded-md p-2 mb-2 
              ${dashboardActiveTabState === item.name ? "bg-green-700" : "hover:bg-green-700"}
              ${isFeatureLocked(userDetailsState, item.name) ? "opacity-50" : ""}`}
            onClick={() => handleNavigation(item)}
          >
            <button className="text-white flex items-center flex-col justify-center">
              <Icon icon={item.icon} width="1.5em" height="1.5em" />
              <p className="text-xs font-normal text-center">{item.name}</p>
            </button>
            {isFeatureLocked(userDetailsState, item.name) && (
              <div className="absolute top-0 right-0 p-1">
                <Icon icon="heroicons:lock-closed" width="1em" height="1em" className="text-yellow-500" />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="md:flex hidden md:items-center md:justify-center hover:bg-green-700 md:rounded-md md:p-2">
        <button
          className="text-white flex items-center flex-col justify-center"
          onClick={() => navigate("/dashboard")}
        >
          <Icon icon="heroicons:pencil-square" width="1.8em" height="1.8em" />
        </button>
      </div>
    </div>
  );
}

export default DashboardSidebar;