import React, { useEffect, useState } from "react";

import { Icon } from "@iconify/react/dist/iconify.js";
import { testimonials } from "../../utils/static";

function Carousel() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 760) {
        setItemsPerPage(1);
      } else {
        setItemsPerPage(4);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalSlides = Math.ceil(testimonials.length / itemsPerPage);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
  };

  const goToSlide = (slideIndex) => {
    setCurrentSlide(slideIndex);
  };

  const startIndex = currentSlide * itemsPerPage;
  const currentTestimonials = testimonials.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  const isFirstPage = currentSlide === 0;
  const isLastPage = currentSlide === totalSlides - 1;
  const isNotFirstPage = !isFirstPage;
  const isNotLastPage = !isLastPage;

  return (
    <div
      id="default-carousel"
      className="relative w-full"
      data-carousel="slide"
    >
      <div className="py-12 px-5 md:px-10 lg:py-36 lg:px-10 xl:px-0">
        <div className="">
          <p className="font-medium text-[40px]">Testimonials</p>
          <p className="font-normal text-pricingText text-base">
            See what our clients have to say about their experiences with us.
          </p>
        </div>
        <div className="w-full flex justify-center">
          <div className="mt-10 ease-in-out duration-300">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-10">
              {currentTestimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className="rounded-[20px] p-6 mb-5 md:mb-8 lg:mb-0 bg-white"
                  style={{
                    "boxShadow":
                      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                  }}
                >
                  <p className="font-normal text-base text-pricingText">
                    {testimonial.text}
                  </p>
                  <div className="flex justify-between mt-8">
                    <div>
                      <p className="font-normal text-base">
                        {testimonial.name}
                      </p>
                      <p className="font-normal text-pricingText text-sm">
                        {testimonial.role}
                      </p>
                    </div>
                    <div>
                      <img
                        src={testimonial.img}
                        alt={`person${index}`}
                        className="h-10 w-10 rounded-full"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="absolute z-30 flex items-center justify-center -translate-x-1/2 bottom-5 md:bottom-5 lg:bottom-20 xl:bottom-18 left-1/2 space-x-3 rtl:space-x-reverse">
        <button
          type="button"
          className={`flex items-center justify-center w-8 h-8 rounded-full ${isNotFirstPage
            ? "bg-lendingButton rounded-full"
            : "bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white"
            }`}
          data-carousel-prev
          onClick={prevSlide}
        >
          <Icon
            icon="icon-park-outline:left"
            width="1.2em"
            height="1.2em"
            className={`${isNotFirstPage ? "text-white" : "text-pricingText"}`}
          />
          <span className="sr-only">Previous</span>
        </button>
        <div className="flex space-x-3">
          {[...Array(totalSlides).keys()].map((index) => (
            <button
              key={index}
              type="button"
              className={`w-3 h-3 border-[1px] border-lendingButton rounded-full ${currentSlide === index && "bg-lendingButton"
                }`}
              aria-current={currentSlide === index ? "true" : "false"}
              aria-label={`Slide ${index + 1}`}
              onClick={() => goToSlide(index)}
            />
          ))}
        </div>
        <button
          type="button"
          className={`flex items-center justify-center w-8 h-8 rounded-full ${isNotLastPage
            ? "bg-lendingButton rounded-full"
            : "bg-white/30 group-hover:bg-white/50 group-focus:ring-4 group-focus:ring-white"
            }`}
          data-carousel-next
          onClick={nextSlide}
        >
          <Icon
            icon="ic:round-navigate-next"
            width="1.5em"
            height="1.5em"
            className={`${isNotLastPage ? "text-white" : "text-pricingText"}`}
          />

          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  );
}

export default Carousel;
