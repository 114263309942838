import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import apiClient from "../../utils/axiosInstance";
import {
  deleteUserLimitedChat,
  getStarredThreads,
  starredThread,
  updateChatNotification,
} from "../../store/finChat";
import { Icon } from "@iconify/react/dist/iconify.js";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import Modal from "../Modal";

const SkeletonLoader = () => (
  <div className="animate-pulse space-y-3">
    {[1, 2, 3, 4, 5].map((item) => (
      <div key={item} className="flex items-center space-x-2">
        <div className="w-6 h-6 bg-gray-200 rounded-full"></div>
        <div className="flex-1 h-4 bg-gray-200 rounded"></div>
      </div>
    ))}
  </div>
);

function SidebarRenderStarred({ isItemActive, setActiveItemId }) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { starredThreadState, starredStatus } = useAppSelector((state) => ({
    starredThreadState: state.finChat.starredThreads,
    starredStatus: state.finChat.starredStatus,
  }));
  const [showButtons, setShowButtons] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [discardedThreadId, setDiscardedThreadId] = useState(null);

  const handleItemClick = (itemId) => {
    setActiveItemId(itemId);
  };

  useEffect(() => {
    if (!starredThreadState?.threads?.length > 0) {
      dispatch(getStarredThreads());
    }
  }, []);

  useEffect(() => {
    if (
      location?.state?.isFirstTime &&
      starredThreadState?.threads?.length > 0
    ) {
      const starredThread = starredThreadState?.threads[0];
      setActiveItemId(starredThread.threadId);
      if (starredThread.type === "chat") {
        navigate(`/answer-search/${starredThread.threadId}`);
      } else {
        navigate(`/thread/${starredThread.threadId}`);
      }
    }
  }, [location?.state, starredThreadState]);

  const resetThreadChatsNotification = async (threadId) => {
    try {
      await apiClient().put(`/reset-notification/${threadId}`);
      dispatch(updateChatNotification({ threadId: threadId, notification: 0 }));
    } catch (error) {
      console.error("Error resetting notification:", error);
    }
  };

  const handleStarredThread = async (threadId) => {
    try {
      dispatch(starredThread(threadId));
      await apiClient().put(`/thread-starred-discarded/${threadId}`, {
        field: "starred",
      });
    } catch (error) {
      console.error("Error starring thread:", error);
    }
  };

  const discardedThread = async () => {
    try {
      await apiClient().put(`/thread-starred-discarded/${discardedThreadId}`, {
        field: "discarded",
      });
      dispatch(deleteUserLimitedChat(discardedThreadId));
    } catch (error) {
      console.error("Error discarding thread:", error);
    }
  };

  const handleDiscarded = (threadId) => {
    setShowModal(true);
    setDiscardedThreadId(threadId);
  };

  const confirmDiscardedThread = () => {
    discardedThread();
    setShowModal(false);
    setShowButtons(false);
  };

  return (
    <ul className="space-y-2 px-3 py-1 ">
      <li>
        <div className="flex items-center justify-between py-5 border-b-2">
          <p className="text-xl font-bold">Starred</p>
        </div>
      </li>
      {starredStatus === "loading" ? (
        <SkeletonLoader />
      ) : starredThreadState?.length === 0 ||
        starredThreadState?.threads?.length === 0 ? (
        <div className="flex justify-center">
          <p className="text-secondary text-sm ">No starred threads</p>
        </div>
      ) : (
        starredThreadState?.threads?.map((item, index) => (
          <li
            key={index}
            className={`text-sm flex  items-center p-2 rounded-lg ${isItemActive(item.threadId) ? "bg-[#00665E29]" : ""
              }`}
          >
            <div
              className="relative group"
              onMouseEnter={() => setShowButtons(index)}
              onMouseLeave={() => setShowButtons(false)}
            >
              <div className="flex items-center">
                <Icon
                  icon="heroicons:chat-bubble-left-ellipsis"
                  width="1.5em"
                  height="1.5em"
                  className="text-pricingText mr-2"
                />
                <Link
                  to={
                    item.type === "chat"
                      ? `/answer-search/${item.threadId}`
                      : `/thread/${item?.threadId}`
                  }
                  className={`truncate ${item.notification > 0 ? 'w-48' : 'w-56'} flex-grow text-landingText capitalize  font-normal ${item.notification > 0 ? "text-gray-900 font-semibold" : ""
                    }`}
                  onClick={(e) => {
                    handleItemClick(item.threadId);
                    if (item.notification > 0) {
                      e.preventDefault();
                      resetThreadChatsNotification(item.threadId).then(() => {
                        navigate(
                          item.type === "chat"
                            ? `/answer-search/${item.threadId}`
                            : `/thread/${item?.threadId}`
                        );
                      });
                    }
                  }}
                  title={item.chatName}
                >
                  {item.chatName}
                </Link>
              </div>
              {showButtons === index && (
                <div
                  className={`absolute right-0 top-[-4px] flex space-x-2 rounded-md px-1 py-1 transition-opacity bg-white duration-200 ${showButtons
                    ? "opacity-100"
                    : "opacity-0 group-hover:opacity-100"
                    }`}
                >
                  <p className="text-sm font-normal">
                    {format(new Date(item.createdAt), "d MMM")}
                  </p>
                  <button
                    className="text-lendingButton rounded"
                    onClick={() => {
                      handleStarredThread(item.threadId);
                    }}
                  >
                    {item.starred ? (
                      <Icon icon="mdi:star" width="1.5em" height="1.5em" />
                    ) : (
                      <Icon
                        icon="mdi:star-outline"
                        width="1.5em"
                        height="1.5em"
                      />
                    )}
                  </button>
                  <button
                    className="text-lendingButton rounded"
                    onClick={() => handleDiscarded(item.threadId)}
                  >
                    <Icon
                      icon="gravity-ui:bell-slash"
                      width="1.2em"
                      height="1.2em"
                    />
                  </button>
                </div>
              )}
            </div>

            {item.notification > 0 && (
              <span className="bg-red-500 text-white text-xs font-bold w-5 h-5 p-[1.5px] rounded-full ml-2 text-center">
                {item.notification}
              </span>
            )}
          </li>
        ))
      )}
      <Modal
        showModal={showModal}
        setShowModal={setShowModal}
        confirmAction={confirmDiscardedThread}
        label={"Discard Thread"}
        description={"Are you sure you want to discard this thread? This action cannot be undone and the thread will be permanently removed from your account."}
        confirmText={"Confirm"}
        cancelText={"Cancel"}
      />
    </ul>
  );
}

export default SidebarRenderStarred;
