import { useEffect } from "react";
import Sidebar from "../Components/Sidebar";
import DashboardSidebar from "./DashboardSidebar";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useAppDispatch, useAppSelector } from "../store";
import { getOrganization } from "../store/organization";
import { useNavigate } from "react-router-dom";

function DMSPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { organizationState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
  }));

  useEffect(() => {
    if (organizationState) {
      dispatch(getOrganization());
    }
  }, []);

  return (
    <>
      <div>
        <div className="flex md:flex-row flex-col">
          <DashboardSidebar />
          <Sidebar />
          <div className={`bg-backgroundColor header-height-1 w-full md:px-0 px-2 my-pb-20`}>
            <div>
              <div className="px-4 py-3 flex justify-between items-center border-b-2">
                <div className="flex items-center ">
                  <p className="text-xl flex items-center text-textPrimary font-medium">
                    <Icon
                      icon="flowbite:messages-outline"
                      width="1.3em"
                      height="1.3em"
                      className="mr-2"
                    />
                    Direct Messages
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="flex flex-col">
                  {organizationState &&
                    organizationState[0]?.members?.map((member, index) => {
                      return (
                        <div
                          key={index}
                          className="border-b-2 p-4"
                          onClick={() => navigate(`/dm/${member.userId}`)}
                        >
                          <p className="flex items-center text-lg font-normal capitalize">
                            <Icon
                              icon="tdesign:member"
                              width="1.2em"
                              height="1.2em"
                              className="mr-1"
                            />
                            {member.name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DMSPage;
