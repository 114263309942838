import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react/dist/iconify.js";
import apiClient from "../utils/axiosInstance";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  url: Yup.string().url("Invalid URL").required("URL is required"),
  question: Yup.string()
    .required("Question is required")
    .min(5, "Question must be at least 5 characters"),
});

function WebChatModel({ isOpenWebModal, setIsOpenWebModal }) {
  const navigate = useNavigate();
  const initialValues = {
    url: "",
    query: "",
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await apiClient().post("/chat", {
        action: "webchat",
        url: values.url,
        query: values.question,
        createdAt: new Date().toISOString(),
      });
      resetForm();
      setIsOpenWebModal(false);
      const threadId = response?.data?.threadId;
      if (threadId) {
        navigate(`/answer-search/${threadId}`);
      }
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      {}
      {isOpenWebModal && (
        <div className="fixed pl-64 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="p-4 md:p-8 rounded-lg w-[350px] md:w-[600px] bg-white">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="bg-white">
                  <div className="flex items-center justify-between">
                    <h2 className="text-2xl font-bold mb-4">Web chat</h2>
                    <button
                      onClick={() => setIsOpenWebModal(false)}
                      className="p-2 hover:bg-gray-100 rounded-full"
                    >
                      <Icon
                        icon="iconamoon:close"
                        width="1.5em"
                        height="1.5em"
                      />
                    </button>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="url"
                      className="block text-sm font-medium text-gray-700"
                    >
                      URL
                    </label>
                    <Field
                      type="url"
                      id="url"
                      name="url"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <ErrorMessage
                      name="url"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="question"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Question
                    </label>
                    <Field
                      as="textarea"
                      id="question"
                      name="question"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 resize-none"
                      rows="3"
                    />
                    <ErrorMessage
                      name="question"
                      component="div"
                      className="text-red-500 text-sm mt-1"
                    />
                  </div>
                  <button
                    type="submit"
                    className="bg-lendingButton w-full text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Processing..." : "Chat"}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
}

export default WebChatModel;
