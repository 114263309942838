import { Icon } from "@iconify/react/dist/iconify.js";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import apiClient from "../../utils/axiosInstance";
import { useAppDispatch } from "../../store";
import { addFolder, updateFolderName } from "../../store/DocumentSlice";

function FolderModal({ setFolderModal, folderModal, initialFolder = null, setInitialFolder }) {
    const dispatch = useAppDispatch();

    const validationSchema = Yup.object({
        folderName: Yup.string()
            .required('Folder name is required')
            .min(2, 'Folder name must be at least 2 characters')
            .max(50, 'Folder name must be at most 50 characters'),
    });

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            setSubmitting(true);
            if (initialFolder) {
                await apiClient().put(`/folder/${initialFolder.folderId}`, {
                    folderName: values.folderName,
                });
                dispatch(updateFolderName({
                    folderId: initialFolder.folderId,
                    folderName: values.folderName,
                }));
            } else {
                const response = await apiClient().post(`/folder`, {
                    folderName: values.folderName,
                });
                dispatch(addFolder(response.data.folder));
            }
            setFolderModal(false);
        } catch (error) {
            toast.error(initialFolder ? "Error while updating team" : "Error while creating team");
        } finally {
            setSubmitting(false);
            setInitialFolder(null);
        }
    };

    const closeModal = () => {
        setFolderModal(false);
        if (initialFolder) setInitialFolder(null);
    };

    return (
        <>
            {folderModal && (
                <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg overflow-hidden shadow-xl w-[350px] md:w-[450px] h-auto">
                        <div className="p-6">
                            <Formik
                                initialValues={{
                                    folderName: initialFolder ? initialFolder.folderName : '',
                                }}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form className="bg-white">
                                        <div className="flex items-center justify-between mb-6">
                                            <h2 className="text-2xl font-bold">{initialFolder ? "Update Folder" : "Create Folder"}</h2>
                                            <button onClick={closeModal} className="p-2 hover:bg-gray-100 rounded-full transition duration-200">
                                                <Icon icon="iconamoon:close" width="1.5em" height="1.5em" />
                                            </button>
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="folderName" className="block text-gray-700 text-left font-medium mb-2">
                                                Folder Name
                                            </label>
                                            <Field
                                                id="folderName"
                                                name="folderName"
                                                type="text"
                                                className="border border-gray-300 rounded-md p-3 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                                                placeholder="Enter folder name"
                                            />
                                            <ErrorMessage name="folderName" component="div" className="text-red-500 text-sm mt-1 text-start" />
                                        </div>
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="w-full mt-4 bg-lendingButton text-white font-medium py-3 px-4 rounded-md hover:bg-opacity-90 transition duration-200"
                                        >
                                            {isSubmitting ? "Processing..." : (initialFolder ? "Update Folder" : "Create Folder")}
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default FolderModal;