import React, { useEffect, useState, useRef, useCallback } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Sidebar from "../Components/Sidebar";
import { useAppDispatch, useAppSelector } from "../store";
import { useNavigate, useParams } from "react-router-dom";
import apiClient from "../utils/axiosInstance";
import { addChat, getThreadChats, removeChat, resetChat, updateMessage, updateSpaceMessageCounter } from "../store/ThreadSlice";
import useAuth from "../utils/useAuth";
import { ContentState, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Members from "../Components/Team/Members";
import InviteModal from "../Components/sidebar/InviteModal";
import { useSocket } from "../context/SocketProvider";
import { v4 as uuidv4 } from "uuid";
import DashboardSidebar from "./DashboardSidebar";
import {
  MessageList,
  EmptyState,
  MessageSkeleton
} from '../Components/SharedMessageComponents';
import { getTeams } from "../store/teamSlice";
import MessageEditor from "../Components/MessageEditor";
import { toast } from "react-toastify";
import ReplyMessages from "../Components/ReplyMessages";

function Team() {
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const { teamId } = useParams();
  const { socket } = useSocket();
  const messagesEndRef = useRef(null);
  const { threadChatState, teamState } = useAppSelector((state) => ({
    teamState: state.teams.teams,
    threadChatState: state.thread.threadChats,
  }));
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [inviteModule, setInviteModule] = useState(false);
  const [isLoadingChats, setIsLoadingChats] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [sharedFolders, setSharedFolders] = useState([]);
  const [isAnotherspace, setIsAnotherspace] = useState(false);
  const [isLoadingTeamData, setIsLoadingTeamData] = useState(false);
  const [team, setTeam] = useState(null);
  const [mentionState, setMentionState] = useState({
    showAskFin: true,
    askFinTagged: false,
  });
  const [taggedFolders, setTaggedFolders] = useState([]);
  const [isReplying, setIsReplying] = useState(false);

 
  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [threadChatState, scrollToBottom]);

  useEffect(() => {
    const fetchSharedFolders = async () => {
      if (teamId) {
        try {
          const response = await apiClient().post(`/get-shared-folders`, {
            sharedTo: [teamId],
            isForDm: false,
          });
          setSharedFolders(response.data.folders);
        } catch (error) {
          console.error('Error fetching shared folders:', error);
        }
      }
    };
    fetchSharedFolders();
  }, [teamId]);

  useEffect(() => {
    if (teamId) {
      const fetchTeamById = async () => {
        setIsLoadingTeamData(true);
        try {
          const response = await apiClient().get(`/team/${teamId}`);
          setTeam(response.data.team);
        } catch (error) {
          console.log('Error fetching team:', error);
        } finally {
          setIsLoadingTeamData(false);
        }
      }

      if (teamState.length > 0) {
        const findTeam = teamState.find((team) => team.teamId === teamId)
        if (!findTeam) {
          setIsAnotherspace(true);
          fetchTeamById();
        } else {
          setTeam(findTeam);
        }
      }
    }
  }, [teamId, teamState])

  useEffect(() => {
    if (teamState.length === 0) {
      dispatch(getTeams());
    }
  }, [])

  useEffect(() => {
    setIsLoadingChats(true);
    dispatch(getThreadChats({ threadId: teamId }))
      .then(() => setIsLoadingChats(false))
      .catch(() => setIsLoadingChats(false));
    return () => dispatch(resetChat());
  }, [teamId, dispatch]);




  useEffect(() => {
    if (!user || !socket) return;

    socket.emit("join_space_room", {
      room: `space-room-${teamId}`,
      userId: user.userId,
    });

    const handleNewMessage = ({ message }) => {
      dispatch(addChat(message));
      setEditorState(EditorState.createEmpty());
      setIsApiCalling(false);
    };

    socket.on("receive_space_message", handleNewMessage);
    socket.on("space_reply_message", ({ message }) => {
      if (isReplying === false) {
        const data = {
          messageId:message.messageId,
          profilePicturesUsers:message.profilePicturesUsers
        }
        dispatch(updateSpaceMessageCounter(data));
      }
    })


    return () => {
      socket.emit("leave_space_room", { room: `space-room-${teamId}` });
      socket.off("receive_space_message", handleNewMessage);
      socket.off("space_reply_message");
    };
  }, [teamId, user, socket, dispatch, isReplying]);

  const handleSendMessage = async (editorState) => {
    let message = "";
    const hasText = editorState.getCurrentContent().hasText();
    const hasFiles = selectedFiles.length > 0;

    if ((!hasText && !hasFiles) || uploadingFiles.length > 0) return;

    setIsApiCalling(true);
    message = editorState.getCurrentContent().getPlainText();

    const baseMessageData = {
      threadId: teamId,
      messageId: uuidv4(),
      isThread: false,
      tag: [{ accountType: "team", tagId: teamId }],
      createdAt: new Date().toISOString(),
      senderId: user?.userId,
      senderName: user?.name,
    };

    let messageData;

    if (hasText && hasFiles) {
      messageData = {
        ...baseMessageData,
        message: message,
        messageType: "text-file",
        files: selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        })),
      };
    } else if (hasText) {
      messageData = {
        ...baseMessageData,
        message: message,
        messageType: "text",
      };
    } else if (hasFiles) {
      messageData = {
        ...baseMessageData,
        messageType: "file",
        files: selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        })),
      };
    }

    await sendMessage(messageData);
    setIsApiCalling(false);
  };

  const sendMessage = async (messageData) => {
    let messageId = uuidv4();
    const data = {
      room: `space-room-${teamId}`,
      message: messageData,
      teamId: teamId,
      teamName: team?.teamName,
    };

    socket.emit("new_space_message", data);
    if (mentionState.askFinTagged || messageData.message.includes("@askfin")) {
      dispatch(addChat(messageData));
      const tempAiData = {
        createdAt: new Date().toISOString(),
        answer: {
          answers: [
            {
              answer: "askfin is thinking...",
            }
          ]
        },
        messageId: messageId,
        isAiChat: true,
        messageType: "text",
        receivers: messageData.tag,
        senderId: messageData.senderId,
        senderName: messageData.senderName,
        threadId: teamId,
      }
      dispatch(addChat(tempAiData));
    } else {
      dispatch(addChat(messageData));
    }
    setEditorState(EditorState.createEmpty());
    setSelectedFiles([]);

    try {
      const apiMessageData = {
        threadId: messageData.threadId,
        messageId: messageData.messageId,
        isThread: false,
        tag: messageData.tag,
        createdAt: messageData.createdAt,
        messageType: messageData.messageType,
      };

      if (messageData.messageType === "text" || messageData.messageType === "text-file") {
        apiMessageData.message = messageData.message;
      }

      if (messageData.messageType === "file" || messageData.messageType === "text-file") {
        apiMessageData.files = messageData.files;
      }

      await apiClient().post("/thread", apiMessageData);
      if (mentionState.askFinTagged || messageData.message.includes("@askfin")) {
        if (taggedFolders.length > 0) {
          try {
            const payload = {
              threadId: teamId,
              folderIds: taggedFolders,
              query: messageData.message,
              messageId: messageId,
              createdAt: new Date().toISOString(),
              senderId: messageData.senderId,
              receivers: messageData.tag,
              type: "thread",
            }
            const response = await apiClient().post("/chatwithfolder", payload);
            const answer = {
              answers: response.data.response
            }
            dispatch(updateMessage({ messageId: messageId, answer: answer }));
          } catch (error) {
            dispatch(removeChat(messageId));
            toast.error(error);
          }
        } else {
          try {
            let body = {
              messageId: messageId,
              query: messageData.message.replace("@askfin", "").trim(),
              action: "chat",
              createdAt: new Date().toISOString(),
              threadId: teamId,
              tag: messageData.tag,
            };

            const response = await apiClient().post(`/chat`, body);
            dispatch(updateMessage({ messageId: messageId, answer: response.data.answer }));
          } catch (error) {
            dispatch(removeChat(messageId));
            toast.error(error);
          }
        }
      }
    } catch (error) {
      console.error("Error posting message:", error);
    }
  };

  const handleRelatedQueryClick = (query) => {
    setMentionState({ ...mentionState, askFinTagged: true });
    const prefixedQuery = `@askfin ${query}`;
    const newEditorState = EditorState.createWithContent(ContentState.createFromText(prefixedQuery));
    handleSendMessage(newEditorState);
  };
  const navigate = useNavigate()
  return (
    <>
      <div className="flex header-heigth-1 bg-backgroundColor">
        <div className="flex md:flex-row flex-col w-full">
          <DashboardSidebar />
          <Sidebar />
          <div className={`flex flex-col ${isReplying ? "md:w-[58%]" : "w-full"} my-pb-20 header-height-1`}>
            <div className={`border-b-2 m-2 ${!isReplying && "xl:px-24"} md:p-2 rounded transition-all duration-300 ease-in-out flex items-center justify-between`}>
              <div className="close-btn md:hidden" >
                <button>
                  <Icon
                    icon="carbon:close"
                    className="w-6 h-6 text-textPrimary"
                    onClick={() => navigate('/dashboard')}
                  />
                </button>
              </div>
              <h1 className="flex items-center text-xl font-semibold">
                <Icon icon="tdesign:member" width="1.2em" height="1.2em" className="mr-1" />
                {team?.teamName || "Loading..."}
              </h1>
              <div className="md:block flex items-center justify-between">
                {isAnotherspace ? (
                  isLoadingTeamData ? (
                    <div className="flex items-center">
                      {[...Array(3)].map((_, index) => (
                        <div key={index} className="-ml-2 first:ml-0">
                          <div className="w-8 h-8 rounded-full bg-gray-300 animate-pulse"></div>
                        </div>
                      ))}
                    </div>
                  ) : team && (
                    <Members
                      teamIds={[teamId]}
                      setInviteModule={setInviteModule}
                      isAnotherspace={isAnotherspace}
                      setIsAnotherspace={setIsAnotherspace}
                      team={team}
                    />
                  )
                ) : (
                  <Members
                    teamIds={[teamId]}
                    setInviteModule={setInviteModule}
                    isAnotherspace={isAnotherspace}
                    team={team}
                  />
                )}
              </div>
            </div>
            <div className="flex-1 flex flex-col overflow-hidden bg-white">
              <div className="flex-1 overflow-y-auto px-0 lg:px-4 ">
                {isLoadingChats ? (
                  <>
                    <MessageSkeleton />
                    <MessageSkeleton />
                    <MessageSkeleton />
                  </>
                ) : threadChatState.length > 0 ? (
                  <MessageList messages={threadChatState} handleRelatedQueryClick={handleRelatedQueryClick} isReplying={isReplying} setIsReplying={setIsReplying} tab="team" />
                ) : (
                  <EmptyState />
                )}
              <div ref={messagesEndRef} />
              </div>
              <MessageEditor
                isAnotherspace={isAnotherspace}
                handleSendMessage={handleSendMessage}
                isApiCalling={isApiCalling}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                uploadingFiles={uploadingFiles}
                setUploadingFiles={setUploadingFiles}
                mentionState={mentionState}
                setMentionState={setMentionState}
                taggedFolders={taggedFolders}
                setTaggedFolders={setTaggedFolders}
                editorState={editorState}
                setEditorState={setEditorState}
                sharedFolders={sharedFolders}
                isReplying={isReplying}
              />
            </div>
          </div>
          {isReplying && <ReplyMessages isReplying={isReplying} setIsReplying={setIsReplying} sharedFolders={sharedFolders} user={user} socket={socket} tab="team" id={teamId} notification={team?.teamName} />}
        </div>
      </div>
      <InviteModal inviteModule={inviteModule} setInviteModule={setInviteModule} />
    </>
  );
}

export default Team;