import { likedChat, newQuery, updateChat } from "../store/finChat";
import { Icon } from "@iconify/react";
import { useState, useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../store/index";
import { toast } from "react-toastify";
import ReportModal from "../Components/ReportModal";
import clipboardCopy from "clipboard-copy";
import ShareModal from "./ShareModal";
import Preview from "./Preview";
import apiClient from "../utils/axiosInstance";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { markdownTable } from "markdown-table";
import ReactMarkdown from "react-markdown";
import useAuth from "../utils/useAuth";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";

function ChatAnswer({
  closeSideBar,
  answerChat,
  setIsChatLoading,
  handleOpenDocumentModal,
  setIsLoading,
  threadId,
  messageId,
  setQuestion,
  selectedOption,
  setScrollId,
  setTicker,
  isLastIndex,
}) {

  const tickerName = answerChat?.answer?.workers?.flatMap((e) => e.ticker);
  const sources = answerChat?.answer?.answers?.map((e) => e.worker);
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const mackDown = useRef();
  const [copy, setCopy] = useState(false);
  const [isShareModal, setIsShareModal] = useState();
  const [copyText, setCopyText] = useState("");
  const [isReportModal, setIsReportModal] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(null);
  const navigate = useNavigate()
  const { chatQueries, chats, toggleState, tickerState } = useAppSelector(
    (state) => ({
      chatQueries: state.finChat.chatQueries,
      chats: state.finChat.chatWithLimit,
      toggleState: state.toggle.toggle,
      tickerState: state.toggle.ticker,
    })
  );

  const handleRelated = async (data) => {
    setQuestion(data);
    try {
      setIsChatLoading(true);
      const body = {
        action: selectedOption.includes("link") ? "webchat" : "chat",
        query: data,
        source: selectedOption,
        threadId: threadId,
        createdAt: new Date().toISOString(),
      };
      if (selectedOption.includes("link")) {
        body.url = chatQueries?.chat?.url;
      }
      const response = await apiClient().post(`/chat`, body);
      if (
        response &&
        chatQueries?.chat?.queries?.length === 1 &&
        chats?.history?.length === 1 &&
        user?.email
      ) {
        posthog.identify(user?.email);
        posthog.capture("follow_up_chat_started", { email: user?.email });
      }
      dispatch(newQuery(response.data));
      dispatch(updateChat(response.data));
      setQuestion("");
    } catch (error) {
      toast.error(error);
    } finally {
      setQuestion("");
      setIsChatLoading(false);
    }
  };

  const handleShare = async () => {
    setIsShareModal(true);
    try {
      setIsLoading(true);
      const body = {
        action: "share",
        threadId: threadId,
      };
      await apiClient().post(`/chat`, body);
      const pageURL = `${window.location.host}/share/${threadId}`;
      setCopyText(pageURL);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLike = async (liked) => {
    if (liked === 1) {
      return;
    }
    try {
      setIsLoading(true);
      const body = {
        action: "like",
        messageId: messageId,
      };
      await apiClient().post(`/chat`, body);
      dispatch(likedChat(messageId));
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);

  const handleCopyAnswer = () => {
    const answerText = answerChat?.answer?.answers?.flatMap((e) => e.answer);
    if (answerText) {
      clipboardCopy(answerText)
        .then(() => {
          setCopy(answerChat);
        })
        .catch((error) => {
          // Handle any errors
        });
    }
  };

  const handleAnalyst = (ticker) => {
    if (!tickerState) {
      return;
    }
    // dispatch(setToggle(true));
    // setTicker(ticker);
  };

  return (
    <>
      <div className="px-4 py-2">
        <div className="flex justify-between mt-4 break-words mb-6">
          <div className="text-md xl:text-xl font-normal capitalize flex items-center justify-between w-full  text-textPrimary">
            <div className="heading-thread flex items-center">
              <div>
                <Icon icon="gridicons:menus" className="mr-2 w-6 h-6" />
              </div>
              <div>
                {answerChat?.query}
                {tickerName
                  ?.filter(
                    (item, index, self) =>
                      index === self.findIndex((t) => t === item)
                  )
                  ?.map((ticker) => {
                    return (
                      <button
                        onClick={() => {
                          handleAnalyst(ticker);
                        }}
                        className="text-sm p-1 font-normal bg-secondary ml-3 text-white rounded-lg"
                      >
                        {ticker}
                      </button>
                    );
                  })}
              </div>
            </div>
            <div className="close-btn md:hidden" >
              <button>
                <Icon
                  icon="carbon:close"
                  className="w-6 h-6 text-textPrimary"
                  onClick={() => navigate('/dashboard')}
                />
              </button>
            </div>

          </div>
          {(sources?.includes("10k") ||
            sources?.includes("10q") ||
            sources?.includes("Transcript")) && (
              <div>
                <button
                  onClick={() => {
                    handleOpenDocumentModal();
                  }}
                  className="document-icon-button text-textPrimary font-bold py-2 px-4 xl:hidden right-0"
                >
                  <Icon
                    icon="arcticons:onlyoffice-documents"
                    className="w-6 h-6"
                  />
                </button>
              </div>
            )}
        </div>

        {answerChat?.answer && (
          <div className="break-words border border-allBorderColor p-5 mb-5 rounded-lg bg-white">
            <p className="text-textPrimary flex items-center text-xl font-medium mb-2">
              Answer
            </p>
            {answerChat?.answer?.answers ? (
              <div>
                {answerChat?.answer?.answers?.map((data, index) => {
                  return (
                    <div key={index} className="relative mb-3">
                      <div className="text-md text-textPrimary font-normal mb-3 relative">
                        <div className="mb-4 markdown-content" ref={mackDown}>
                          <Markdown
                            remarkPlugins={[remarkGfm]}
                          >
                            {data.answer}
                          </Markdown>
                        </div>
                        {data?.tableData && (
                          <div className="table-container my-3">
                            <ReactMarkdown
                              children={markdownTable(data?.tableData)}
                              remarkPlugins={[remarkGfm]}
                            />
                          </div>
                        )}
                        <div className="flex">
                          {data?.context?.map((data, index) => (
                            <>
                              <p
                                key={index}
                                onMouseEnter={() => setIsHovered(index)}
                                onMouseLeave={() => setIsHovered(null)}
                                onClick={() => {
                                  if (isLastIndex) {
                                    setScrollId(
                                      `highlight-${data.match(/\d+/)}`
                                    );
                                  }
                                }}
                                className="bg-secondary text-white text-sm rounded-full h-5 w-5 flex items-center justify-center cursor-pointer mx-1"
                              >
                                {data.match(/\d+/)}
                              </p>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div>
                {
                  <div>
                    <p className="text-md text-secondary font-normal mb-3 relative">
                      {answerChat?.answer?.answer}
                    </p>
                  </div>
                }
              </div>
            )}
            <div className="flex items-center mt-4 justify-between">
              <div className="flex items-center mt-4">
                {/* <button
                  onClick={handleShare}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor px-2 py-1 rounded-full"
                >
                  <Icon
                    icon="ph:share-fat"
                    className="text-[22px] mr-1 text-[#AEAEAE]"
                  />
                  Share
                </button> */}
                <button
                  onClick={handleCopyAnswer}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-1 rounded-full"
                >
                  <Icon
                    icon={copy ? "uit:check" : "icon-park-outline:clipboard"}
                    className={`text-xl mr-1 ${copy ? " text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Copy
                </button>
              </div>
              <div className="flex items-center mt-4">
                <button
                  onClick={() => {
                    handleLike(answerChat.liked);
                  }}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-0.5 rounded-full"
                >
                  <Icon
                    icon="ei:like"
                    className={`text-3xl mr-1 ${answerChat.liked === 1 ? "text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Like
                </button>
                <button
                  onClick={() => setIsReportModal(!isReportModal)}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-1 rounded-full"
                >
                  <Icon
                    icon="solar:dislike-broken"
                    className={`text-2xl mr-1 ${answerChat.report === 1 ? "text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Report
                </button>
              </div>
            </div>
          </div>
        )}

        {answerChat?.answer?.answers && (
          <div className="mb-8">
            <p className="text-textPrimary flex items-center text-lg font-medium mb-2">
              Sources
            </p>
            <div className=" h-26 items-normal">
              {answerChat?.answer?.answers
                ?.filter(
                  (item, index, self) =>
                    index === self.findIndex((t) => t.source === item.source)
                )
                ?.flatMap((data, index) => {
                  return (
                    <div
                      key={index}
                      className="relative"
                      onMouseEnter={() => setHoveredIndex(index)}
                      onMouseLeave={() => setHoveredIndex(null)}
                    >
                      {Array.isArray(data.source) ? (
                        data.source.map((source, sourceIndex) => (
                          <div
                            key={sourceIndex}
                            className={`flex border-b-2 border-allBorderColor p-2 tran items-normal ${hoveredIndex === index
                              ? "hover:bg-allBorderColor"
                              : ""
                              } ${!toggleState ? "h-18" : "h-18"
                              } mr-3 mb-2 cursor-pointer `}
                          >
                            <p className="text-md font-medium text-secondary mr-4">
                              {index + 1}.
                            </p>
                            <Preview
                              imageUrl={source}
                              hoveredIndex={hoveredIndex}
                              index={index}
                            />
                            <a
                              target="_blank"
                              href={source}
                              className={`${!toggleState ? "h-18" : "h-18"
                                } text-sm break-words overflow-hidden truncate overflow-ellipsis text-secondary font-normal`}
                            >
                              {source}
                            </a>
                          </div>
                        ))
                      ) : data.source ? (
                        <div
                          className={`flex border-b-2 border-allBorderColor p-2 tran items-normal ${hoveredIndex === index
                            ? "hover:bg-allBorderColor"
                            : ""
                            } ${!toggleState ? "h-18" : "h-18"
                            } mr-3 mb-2 cursor-pointer `}
                        >
                          <p className="text-sm font-medium text-secondary mr-2">
                            {index + 1}.
                          </p>
                          <Preview
                            imageUrl={data.source}
                            hoveredIndex={hoveredIndex}
                            index={index}
                          />
                          <a
                            target="_blank"
                            href={data.source}
                            className={`${!toggleState ? "h-18" : "h-18"
                              } text-sm break-words overflow-hidden truncate overflow-ellipsis text-secondary font-normal`}
                          >
                            {data.source}
                          </a>
                        </div>
                      ) : (
                        <div
                          className={`flex bg-sidebarBgColor rounded-lg p-2 items-start ${hoveredIndex === index
                            ? "hover:bg-allBorderColor"
                            : ""
                            } mr-3 mb-2 cursor-pointer`}
                        >
                          <p className="text-sm break-words md:w-48 h-10 overflow-hidden overflow-ellipsis text-secondary font-normal">
                            The source is not available
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {answerChat?.answer?.questions && (
          <div className="mt-8 border border-allBorderColor p-5 mb-5 rounded-lg bg-white">
            <p className="flex items-center text-md font-medium mb-4">
              Related
            </p>
            {answerChat?.answer?.questions.flatMap((data, index) => {
              return (
                <div className="w-full" key={index}>
                  <button
                    onClick={() => handleRelated(data)}
                    className="text-textPrimary border-t-2 text-sm text-left w-full border-allBorderColor p-3 hover:hover:bg-allBorderColor"
                  >
                    {data}
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <ShareModal
        answerChat={answerChat}
        isShareModal={isShareModal}
        setIsShareModal={setIsShareModal}
        copyText={copyText}
        setCopyText={setCopyText}
      />

      {answerChat?.report !== 1 && (
        <ReportModal
          isReportModal={isReportModal}
          setIsReportModal={setIsReportModal}
          messageId={messageId}
        />
      )}
    </>
  );
}

export default ChatAnswer;
