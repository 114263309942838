import { Icon } from "@iconify/react/dist/iconify.js";
import { useAppDispatch, useAppSelector } from "../store";
import { useEffect, useState } from "react";
import { newQuery } from "../store/finChat";
import apiClient from "../utils/axiosInstance";
import { Sources } from "../utils/static";

function ChatBox({
  setIsChatLoading,
  selectedOption,
  setSelectedOption,
  threadId,
  setQuestion,
  questions,
}) {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState("");
  const [sourceState, setSourceState] = useState(Sources);
  const { chatQueries } = useAppSelector((state) => ({
    chatQueries: state?.finChat?.chatQueries,
  }));

  useEffect(() => {
    if (chatQueries?.chat?.source === "link") {
      setSourceState(Sources);
    } else {
      const source = Sources.filter((source) => source.value !== "link");
      setSourceState(source);
    }
  }, [chatQueries?.chat?.source]);

  useEffect(() => {
    if (chatQueries?.chat?.source) {
      setSelectedOption([chatQueries.chat.source]);
    }
  }, [chatQueries]);

  const handleCheckboxChange = (source) => {
    let updatedSelectedOption = [...selectedOption];

    if (chatQueries?.chat?.source === "link" && source !== "link") {
      return;
    }

    if (source !== "auto") {
      if (selectedOption.includes("auto")) {
        const autoIndex = updatedSelectedOption.indexOf("auto");
        updatedSelectedOption.splice(autoIndex, 1);
      }
      if (updatedSelectedOption.includes(source)) {
        const sourceIndex = updatedSelectedOption.indexOf(source);
        updatedSelectedOption.splice(sourceIndex, 1);
      } else {
        updatedSelectedOption.push(source);
      }
    } else {
      updatedSelectedOption = ["auto"];
    }
    setSelectedOption(updatedSelectedOption);
  };

  const handleChat = async () => {
    if (chatQueries?.chat?.source === "link") {
      setQuestion(query);
      setQuery("");
      setIsChatLoading(true);
      try {
        const body = {
          action: "webchat",
          query: query,
          url: chatQueries?.chat?.url,
          createdAt: new Date().toISOString(),
        };
        const response = await apiClient().post(`/chat`, body);
        dispatch(newQuery(response.data));
        setQuestion("");
      } catch (error) {
        console.log("error in chat:", error);
      } finally {
        setIsChatLoading(false);
      }
    } else {
      setQuestion(query);
      setQuery("");
      setIsChatLoading(true);
      try {
        const body = {
          action: "chat",
          query: query,
          source: selectedOption,
          threadId: threadId,
          previous_queries: questions,
          createdAt: new Date().toISOString(),
        };
        const response = await apiClient().post(`/chat`, body);
        dispatch(newQuery(response.data));
        setQuestion("");
      } catch (error) {
        console.log("error in chat:", error);
      } finally {
        setIsChatLoading(false);
      }
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className="md:sticky fixed md:bottom-0 bottom-[3%]  md:h-42 lg:h-40 bg-[#f3f3ee] p-2 pb-20 md:pb-0 xl:pb-0">
      <div className="flex items-center mt-3 mb-2 ml-3 flex-wrap md:flex-wrap lg:flex-wrap xl:flex-wrap ">
        <div>
          <p className="text-sm text-secondary font-medium ">Sources :</p>
        </div>
        {sourceState.map((item, index) => {
          const isLinkCheckbox = item.value === "link";
          const isLinkSource = chatQueries?.chat?.source === "link";

          return (
            <>
              <div key={index} className="flex items-center cursor-pointer p-2">
                <input
                  checked={selectedOption.includes(item.value)}
                  onChange={() => handleCheckboxChange(item.value)}
                  id={`checked-checkbox-${index}`}
                  type="checkbox"
                  value={item.value}
                  disabled={isLinkCheckbox && isLinkSource}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
                />
                <label
                  htmlFor={`checked-checkbox-${index}`}
                  className="ms-2 text-sm font-medium text-secondary"
                >
                  {item.name}
                </label>
              </div>
              {index !== Sources.length - 1 ? (
                <hr className="h-6 bg-borderColor border-[1px]" />
              ) : null}
            </>
          );
        })}
      </div>
      <div className="mb-5">
        <div className="p-2 border border-borderColor  bg-backgroundColor rounded-full mb-5">
          <div className="input-container flex items-center ">
            <input
              type="text"
              value={query}
              onChange={handleInputChange}
              className="form-input px-4 py-2 w-full border-none focus:outline-none focus:ring-0 focus:border-none"
              placeholder="Ask anything..."
              onKeyDown={(e) => {
                if (e?.key === "Enter") handleChat();
              }}
            />
            <div>
              <button
                onClick={handleChat}
                disabled={!query}
                className={`flex items-center p-2 ml-5 ${!query ? "bg-gray-100" : "bg-lendingButton text-white"
                  } rounded-full font-normal text-lg group`}
              >
                <Icon icon="fluent:arrow-up-48-filled" className="text-xl" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChatBox;
