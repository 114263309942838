import React from "react";

function Modal({ showModal, setShowModal, confirmAction, label, description, confirmText, cancelText, note }) {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full">
        <div className="p-6">
          <h2 className="text-2xl font-bold mb-4">{label}</h2>
          <p className="mb-2">{description}</p>
          {note && (
            <p className="text-sm text-gray-500 mb-2">{note}</p>
          )}
          <div className="flex justify-end space-x-4">
            <button
              onClick={confirmAction}
              className="bg-lendingButton text-white font-bold py-2 px-4 rounded"
            >
              {confirmText}
            </button>
            <button
              onClick={() => setShowModal(false)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
            >
              {cancelText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
