import { Icon } from "@iconify/react";
import React from "react";
import clipboardCopy from "clipboard-copy";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

function ShareModal({
  item,
  isShareModal,
  setIsShareModal,
  copyText,
  setCopyText,
  answerChat,
}) {
  const closeModal = () => {
    setIsShareModal(false);
    setCopyText("");
  };

  const shareCopy = () => {
    clipboardCopy(copyText)
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
    setIsShareModal(false);
  };

  return (
    <>
      {isShareModal && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="rounded-lg w-[350px] md:w-[600px] bg-white">
            <div className="p-4 md:p-4 flex items-center justify-between">
              <p className="text-color text-2xl font-semibold text-center ">
                Share
              </p>
              <button
                onClick={closeModal}
                className="flex items-center p-2  text-secondary rounded-lg font-normal text-lg hover:bg-gray-300 group"
              >
                <Icon icon="iconamoon:close-light" className="text-2xl" />
              </button>
            </div>

            <div className="px-6 pt-3 flex items-center justify-between overflow-y-auto mb-8">
              <button className="mr-5 text-center">
                <FacebookShareButton
                  url={copyText}
                  title={answerChat?.query ? answerChat.query : item.query}
                >
                  <FacebookIcon round />
                  <p className="mt-3 text-md text-secondary">Facebook</p>
                </FacebookShareButton>
              </button>
              <button className="mr-5 text-center">
                <TwitterShareButton
                  url={copyText}
                  title={answerChat?.query ? answerChat.query : item.query}
                >
                  <XIcon size={56} round />
                  <p className="mt-3 text-md text-secondary">Twitter</p>
                </TwitterShareButton>
              </button>
              <button className="mr-5 text-center">
                <TelegramShareButton
                  url={copyText}
                  title={answerChat?.query ? answerChat.query : item.query}
                >
                  <TelegramIcon size={56} round />
                  <p className="mt-3 text-md text-secondary">Telegram</p>
                </TelegramShareButton>
              </button>
              <button className="mr-5 text-center">
                <WhatsappShareButton
                  url={copyText}
                  title={answerChat?.query ? answerChat.query : item.query}
                >
                  <WhatsappIcon size={56} round />
                  <p className="mt-3 text-md text-secondary">Whatsapp</p>
                </WhatsappShareButton>
              </button>
              <button className="mr-5 text-center">
                <LinkedinShareButton
                  url={copyText}
                  title={answerChat?.query ? answerChat.query : item.query}
                >
                  <LinkedinIcon size={56} round />
                  <p className="mt-3 text-md text-secondary">Linkedin</p>
                </LinkedinShareButton>
              </button>
              <button className="mr-5 text-center">
                <EmailShareButton
                  subject="finsource"
                  body={copyText}
                  title={answerChat?.query ? answerChat.query : item.query}
                >
                  <EmailIcon size={56} round />
                  <p className="mt-3 text-md text-secondary">Email</p>
                </EmailShareButton>
              </button>
            </div>
            {copyText && (
              <div className="mx-5 mb-8">
                <div>
                  <div className="p-2 border-allBorderColor rounded-lg border-[1px] truncate  flex items-center justify-between">
                    <p className="truncate w-52 md:w-96 lg:w-96">{copyText}</p>
                    <button
                      className="bg-blue-600 rounded-full text-white px-4 py-2 text-lg"
                      onClick={shareCopy}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ShareModal;
