import React, { useEffect, useState, useRef } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import Sidebar from "../Components/Sidebar";
import { useAppDispatch, useAppSelector } from "../store";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import apiClient from "../utils/axiosInstance";
import { addChat, getThreadChats, removeChat, resetChat, updateMessage, updateThreadMessageCounter } from "../store/ThreadSlice";
import useAuth from "../utils/useAuth";
import { ContentState, EditorState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSocket } from "../context/SocketProvider";
import { v4 as uuidv4 } from "uuid";
import DashboardSidebar from "./DashboardSidebar";
import InviteModal from "../Components/sidebar/InviteModal";
import {
  MessageList,
  EmptyState,
  MessageSkeleton
} from '../Components/SharedMessageComponents';
import MessageEditor from "../Components/MessageEditor";
import { toast } from "react-toastify";
import ReplyMessages from "../Components/ReplyMessages";

function Thread() {
  const { threadId } = useParams();
  const { user } = useAuth();
  const { socket } = useSocket();
  const dispatch = useAppDispatch();
  const { threadChatState, userDetailsState } = useAppSelector((state) => ({
    threadChatState: state.thread.threadChats,
    userDetailsState: state.auth.userDetails,
  }));
  const tempRespose = useLocation()?.state?.chat;
  const [thread, setThread] = useState({});
  const messagesEndRef = useRef(null);
  const [sharedFolders, setSharedFolders] = useState([]);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [inviteModule, setInviteModule] = useState(false);
  const [isLoadingThread, setIsLoadingThread] = useState(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [isReplying, setIsReplying] = useState(false);
  const navigate = useNavigate();
  const [mentionState, setMentionState] = useState({
    showAskFin: true,
    askFinTagged: false,
  });
  const [taggedFolders, setTaggedFolders] = useState([]);

  const fetchThread = async () => {
    setIsLoadingThread(true);
    try {
      const response = await apiClient().get(`/thread/${threadId}`);
      const threadData = response.data.thread;
      setThread(threadData);
    } catch (error) {
      console.error("Error fetching thread:", error);
    } finally {
      setIsLoadingThread(false);
    }
  };


  
  useEffect(() => {
    const fetchSharedFolders = async () => {
      if (thread) {
        try {
          const response = await apiClient().post(`/get-shared-folders`, {
            sharedTo: thread?.tag.map((tag) => tag.tagId),
            isForDm: false,
          });
          setSharedFolders(response.data.folders);
        } catch (error) {
          console.error('Error fetching shared folders:', error);
        }
      }
    };
    if (thread) {
      fetchSharedFolders();
    }
  }, [threadId, thread]);

  useEffect(() => {
    const sendMessage = async () => {
      try {
        if (tempRespose) {
          setIsLoadingThread(false);
          setIsLoadingMessages(false);
          setThread({
            role: "admin",
            userId: tempRespose.userId,
            threadId: tempRespose.threadId,
            chatName: tempRespose.chatName,
            tag: tempRespose.tag,
            createdAt: tempRespose.createdAt,
            type: tempRespose.type,
          });

          const chatData = {
            createdAt: tempRespose.createdAt,
            receivers: tempRespose.receivers,
            messageId: tempRespose.messageId,
            message: tempRespose.message,
            threadId: tempRespose.threadId,
            senderId: tempRespose.senderId,
            senderName: tempRespose.senderName,
            messageType: tempRespose.messageType,
          };

          if (tempRespose.files && tempRespose.files.length > 0) {
            chatData.files = tempRespose.files;
          }
          dispatch(addChat(chatData));
          if (tempRespose?.isAiChat) {
            const messageId = uuidv4();
            const tempAiChatData = {
              createdAt: tempRespose.createdAt,
              answer: {
                answers: [
                  {
                    answer: "askfin is thinking...",
                  }
                ]
              },
              messageId: messageId,
              isAiChat: true,
              messageType: "text",
              receivers: tempRespose.receivers,
              senderId: tempRespose.senderId,
              senderName: tempRespose.senderName,
              threadId: tempRespose.threadId,
            }
            dispatch(addChat(tempAiChatData));
            if (tempRespose.taggedFolders.length > 0) {
              try {
                const payload = {
                  threadId: tempRespose.threadId,
                  folderIds: tempRespose.taggedFolders,
                  query: tempRespose.message,
                  messageId: messageId,
                  createdAt: new Date().toISOString(),
                  senderId: tempRespose.senderId,
                  receivers: tempRespose.receivers,
                  type: "thread",
                }
                const response = await apiClient().post("/chatwithfolder", payload);
                const answer = {
                  answers: response.data.response
                }
                dispatch(updateMessage({ messageId: messageId, answer: answer }))
              } catch (error) {
                dispatch(removeChat(messageId));
                toast.error(error);
              }
            } else {
              try {
                let body = {
                  messageId: messageId,
                  query: tempRespose.message.replace("@askfin", "").trim(),
                  action: "chat",
                  createdAt: new Date().toISOString(),
                  threadId: tempRespose.threadId,
                  tag: tempRespose.receivers,
                };

                const response = await apiClient().post(`/chat`, body);
                dispatch(updateMessage({ messageId: messageId, answer: response.data.answer }));
              } catch (error) {
                dispatch(removeChat(messageId));
                toast.error(error);
              }

            }

          }
        } else {
          fetchThread();
          setIsLoadingMessages(true);
          dispatch(getThreadChats({ threadId }))
            .then(() => setIsLoadingMessages(false))
            .catch(() => {
              setIsLoadingMessages(false);
              console.error("Error fetching thread chats");
            });
        }
      } catch (error) {
        console.error("Error posting message:", error);

      }
    }
    sendMessage();
  }, [threadId, dispatch, tempRespose]);

  useEffect(() => {
    return () => {
      dispatch(resetChat());
      setThread({});
      setIsLoadingThread(true);
      setIsLoadingMessages(true);
      setSelectedFiles([]);
      window.history.replaceState({}, document.title);
    };
  }, [threadId, dispatch]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!user || !socket) return;

    socket.emit("join_thread_room", {
      room: `${threadId}`,
      userId: user.userId,
    });

    const handleNewMessage = ({ message }) => {
      dispatch(addChat(message));
      dispatch(updateMessage({ messageId: message.messageId, answer: message.answer }))
      scrollToBottom();
      setEditorState(EditorState.createEmpty());
      setIsApiCalling(false);
    };

    socket.on("receive_main_thread_message", handleNewMessage);
    socket.on("thread_reply_message", ({message}) => {
      if(isReplying === false){
        const data = {
          messageId:message.messageId,
          profilePicturesUsers:message.profilePicturesUsers
        }
        dispatch(updateThreadMessageCounter(data))
      }
      
    })

    return () => {
      socket.emit("leave_thread_room", { room: `${threadId}` });
      socket.off("receive_main_thread_message", handleNewMessage);
      socket.off("thread_reply_message");
    };
  }, [threadId, user, socket, dispatch,isReplying]);

  useEffect(() => {
    scrollToBottom();
  }, [threadChatState]);

  const handleSendMessage = async (editorState) => {
    const hasText = editorState.getCurrentContent().hasText();
    let message = "";
    const hasFiles = selectedFiles.length > 0;

    if ((!hasText && !hasFiles) || uploadingFiles.length > 0) return;

    try {
      setIsApiCalling(true);
      message = editorState.getCurrentContent().getPlainText();

      const baseMessageData = {
        threadId: threadId,
        messageId: uuidv4(),
        isThread: false,
        tag: thread?.tag,
        createdAt: new Date().toISOString(),
        senderId: user?.userId,
        senderName: userDetailsState?.name,
      };

      let messageData;

      if (hasText && hasFiles) {
        messageData = {
          ...baseMessageData,
          message: message,
          messageType: "text-file",
          files: selectedFiles.map((file) => ({
            docS3Loc: file.docS3Loc,
            url: file.url,
            fileName: file.file.name,
          })),
        };
      } else if (hasText) {
        messageData = {
          ...baseMessageData,
          message: message,
          messageType: "text",
        };
      } else if (hasFiles) {
        messageData = {
          ...baseMessageData,
          messageType: "file",
          files: selectedFiles.map((file) => ({
            docS3Loc: file.docS3Loc,
            url: file.url,
            fileName: file.file.name,
          })),
        };
      }

      await sendMessage(messageData);
      setIsApiCalling(false);
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsApiCalling(false);
    }
  };

  const sendMessage = async (messageData) => {
    let messageId = uuidv4();
    const data = {
      room: `${threadId}`,
      message: messageData,
      tag: thread?.tag,
      threadId: threadId,
    };

    socket.emit("new_main_thread_message", data);

    if (mentionState.askFinTagged || messageData.message.includes("@askfin")) {
      dispatch(addChat(messageData));
      const tempAiData = {
        createdAt: new Date().toISOString(),
        answer: {
          answers: [
            {
              answer: "askfin is thinking...",
            }
          ]
        },
        messageId: messageId,
        isAiChat: true,
        messageType: "text",
        receivers: messageData.tag,
        senderId: messageData.senderId,
        senderName: messageData.senderName,
        threadId: threadId,
      }
      dispatch(addChat(tempAiData));
    } else {
      dispatch(addChat(messageData));
    }

    setEditorState(EditorState.createEmpty());
    setSelectedFiles([]);
    scrollToBottom();

    try {
      const apiMessageData = {
        threadId: messageData.threadId,
        messageId: messageData.messageId,
        isThread: false,
        tag: messageData.tag,
        createdAt: messageData.createdAt,
        messageType: messageData.messageType,
      };

      if (messageData.messageType === "text" || messageData.messageType === "text-file") {
        apiMessageData.message = messageData.message;
      }

      if (messageData.messageType === "file" || messageData.messageType === "text-file") {
        apiMessageData.files = messageData.files;
      }

      await apiClient().post("/thread", apiMessageData);
      if (mentionState.askFinTagged || messageData.message.includes("@askfin")) {
        if (taggedFolders.length > 0) {
          try {
            const payload = {
              threadId: threadId,
              folderIds: taggedFolders,
              query: messageData.message,
              messageId: messageId,
              createdAt: new Date().toISOString(),
              senderId: messageData.senderId,
              receivers: messageData.tag,
              type: "thread",
            }
            const response = await apiClient().post("/chatwithfolder", payload);
            const answer = {
              answers: response.data.response
            }
            dispatch(updateMessage({ messageId: messageId, answer: answer }));
          } catch (error) {
            dispatch(removeChat(messageId));
            toast.error(error);
          }
        } else {
          try {
            let body = {
              messageId: messageId,
              query: messageData.message.replace("@askfin", "").trim(),
              action: "chat",
              createdAt: new Date().toISOString(),
              threadId: threadId,
              tag: messageData.tag,
            };

            const response = await apiClient().post(`/chat`, body);
            dispatch(updateMessage({ messageId: messageId, answer: response.data.answer }));
          } catch (error) {
            dispatch(removeChat(messageId));
            toast.error(error);
          }
        }
      }
    } catch (error) {
      console.error("Error posting message:", error);
    }
  };

  const handleRelatedQueryClick = (query) => {
    setMentionState({ ...mentionState, askFinTagged: true });
    const prefixedQuery = `@askfin ${query}`;
    const newEditorState = EditorState.createWithContent(ContentState.createFromText(prefixedQuery));
    handleSendMessage(newEditorState);
  };

  return (
    <>
      <div className="flex header-heigth-1">
        <div className="flex md:flex-row flex-col w-full">
          <DashboardSidebar />
          <Sidebar />
          <div className={`flex flex-col ${isReplying ? "md:w-[58%]" : "w-full"} my-pb-20 header-height-1`}>
            <div className={`border-b-2 m-2 ${!isReplying && "xl:px-20"} md:p-2 rounded transition-all duration-300 ease-in-out`}>
              <div className="close-btn md:hidden" >
                <button>
                  <Icon
                    icon="carbon:close"
                    className="w-6 h-6 text-textPrimary"
                    onClick={() => navigate('/dashboard')}
                  />
                </button>
              </div>
              <h1 className="flex items-center text-xl font-semibold">
                <Icon icon="tdesign:member" width="1.2em" height="1.2em" className="mr-1" />
                {isLoadingThread ? "Loading..." : thread?.chatName}
              </h1>
              <div className="md:block flex items-center justify-between">
                <div className="rounded transition-all duration-300 ease-in-out">
                  <div className="flex items-center justify-between p-1.5 md:p-0">
                    <div>
                      <div className="flex flex-wrap gap-2 mt-4">
                        {thread?.tag &&
                          thread.tag.map((tagItem, index) => (
                            <p
                              key={index}
                              className="flex items-center rounded-full md:text-base text-sm font-medium text-secondary"
                            >
                              <span className="mr-1 font-bold">
                                {tagItem.accountType === "team" ? (
                                  <Icon
                                    icon="ph:microsoft-teams-logo-light"
                                    width="1.5em"
                                    height="1.5em"
                                  />
                                ) : (
                                  <Icon
                                    icon="tdesign:member"
                                    width="1.5em"
                                    height="1.5em"
                                  />
                                )}
                              </span>
                              {tagItem.tagName}
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-col overflow-hidden bg-white">
              <div className="flex-1 overflow-y-auto ">
                {isLoadingMessages ? (
                  <>
                    <MessageSkeleton />
                    <MessageSkeleton />
                    <MessageSkeleton />
                  </>
                ) : threadChatState.length > 0 ? (
                  <MessageList messages={threadChatState} handleRelatedQueryClick={handleRelatedQueryClick} isReplying={isReplying} setIsReplying={setIsReplying} tab="thread" />
                ) : (
                  <EmptyState />
                )}
                <div ref={messagesEndRef} />
              </div>
              <MessageEditor
                editorState={editorState}
                tags={thread?.tag}
                setEditorState={setEditorState}
                handleSendMessage={handleSendMessage}
                isApiCalling={isApiCalling}
                setIsApiCalling={setIsApiCalling}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                uploadingFiles={uploadingFiles}
                setUploadingFiles={setUploadingFiles}
                mentionState={mentionState}
                setMentionState={setMentionState}
                taggedFolders={taggedFolders}
                setTaggedFolders={setTaggedFolders}
                sharedFolders={sharedFolders}
                tab="thread"
                isReplying={isReplying}
              />
            </div>
          </div>
          {isReplying && <ReplyMessages isReplying={isReplying} setIsReplying={setIsReplying} sharedFolders={sharedFolders} user={user} socket={socket} tab="thread" id={threadId} notification={thread?.chatName} />}
        </div>
      </div>
      <InviteModal
        inviteModule={inviteModule}
        setInviteModule={setInviteModule}
      />
    </>
  );
}

export default Thread;