import React, { useEffect, useState } from "react";
import Chat from "../Components/Chat";
import Sidebar from "../Components/Sidebar";
import DashboardSidebar from "./DashboardSidebar";
import { getUserDetails } from "../store/authSlice";
import useAuth from "../utils/useAuth";
import { useAppDispatch, useAppSelector } from "../store";

function Dashboard() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { userDetailsState } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails,
  }));
  const [isOpenWebModal, setIsOpenWebModal] = useState(false);

  useEffect(() => {
    if (user && !userDetailsState) {
      dispatch(getUserDetails(user?.userId));
    }
  }, [user]);

  const [dashboard, SetDashboard] = useState(false);

  return (
    <>
      {userDetailsState &&
        <div className="dashboard-container header-height flex flex-col md:flex-row w-full">
          <div className="dashboard-sidebar-container md:h-16 ">
            <DashboardSidebar />
          </div>
          <div className="dashboard-content-container ">
            <Sidebar
              dashboard={dashboard}
              SetDashboard={SetDashboard}
              isOpenWebModal={isOpenWebModal}
              setIsOpenWebModal={setIsOpenWebModal}
            />
          </div>
          <div className={`dashboard-chat-container w-full my-pb-20 ${dashboard ? "block" : "hidden"} md:block`}>
            <Chat
              isOpenWebModal={isOpenWebModal}
              setIsOpenWebModal={setIsOpenWebModal}
              SetDashboard={SetDashboard}
            />
          </div>
        </div>
      }
    </>
  );
}

export default Dashboard;
