import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import apiClient from "../../utils/axiosInstance";
import { documentChat } from "../../store/DocumentSlice";
import { toast } from "react-toastify";

function DocChatBox({ documentId, setQuestion, setIsChatLoading }) {
  const dispatch = useAppDispatch();
  const [query, setQuery] = useState("");
  const { documentChats } = useAppSelector((state) => ({
    documentChats: state.document.documentChats,
  }));

  const handleChat = async () => {
    setIsChatLoading(true);
    setQuestion(query);
    setQuery("");
    try {
      const body = {
        query: query,
        documentId: documentId,
        createdAt: new Date().toISOString(),
      };
      const response = await apiClient().post(`/chat-doc`, body);
      dispatch(documentChat(response.data.response));
      setQuery("");
      setQuestion("");
    } catch (error) {
      toast.error(error);
      console.log("error in doc chat:", error);
    } finally {
      setQuery("");
      setQuestion("");
      setIsChatLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <>
      <div className="sticky bottom-0 h-40 bg-[#f3f3ee] p-2">
        <div className="flex items-center mt-3 mb-2 ml-3">
          <div className="flex items-center">
            {documentChats?.document?.documentName?.split(".").pop() === "docx" ? (
              <Icon icon="ph:file-doc-bold" className="text-2xl mr-1" />
            ) : documentChats?.document?.documentName?.split(".").pop() === "pdf" ? (
              <Icon icon="ph:file-pdf-bold" className="text-2xl mr-1" />
            ) : documentChats?.document?.documentName?.split(".").pop() === "txt" ? (
              <Icon icon="ph:file-txt-bold" className="text-2xl mr-1" />
            ) : documentChats?.document?.documentName?.split(".").pop() === "jpg" ? (
              <Icon icon="ph:file-jpg-bold" className="text-2xl mr-1" />
            ) : documentChats?.document?.documentName?.split(".").pop() === "png" ? (
              <Icon icon="ph:file-png-bold" className="text-2xl mr-2" />
            ) : (
              ""
            )}
            <p className="text-md font-semibold text-textPrimary">
              {documentChats?.document?.documentName}
            </p>
          </div>
        </div>
        <div className="mb-5">
          <div className="p-2 border border-borderColor bg-backgroundColor rounded-full mb-5">
            <div className="input-container flex items-center">
              <input
                type="text"
                value={query}
                onChange={handleInputChange}
                className="form-input px-4 py-2 w-full border-none focus:outline-none focus:ring-0 focus:border-none"
                placeholder="Ask anything..."
                onKeyDown={(e) => {
                  if (e?.key === "Enter") handleChat();
                }}
              />
              <div>
                <button
                  onClick={handleChat}
                  disabled={!query}
                  className={`flex items-center p-2 ml-5 ${!query ? "bg-gray-100" : "bg-lendingButton text-white"
                    } rounded-full font-normal text-lg group`}
                >
                  <Icon icon="fluent:arrow-up-48-filled" className="text-xl" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocChatBox;
