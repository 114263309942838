import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

const initialState = {
  toggle: true,
  ticker: false,
  drawer: true,
};

export const getTickerQuote = createAsyncThunk("getTickerQuote", async () => {
  try {
    const response = await apiClient().get(`/ticker-quote`);
    return response.data.tickerQuote;
  } catch (error) {
    console.log("error in chat slice:", error);
    toast.error(`${error}`);
  }
});

export const toggleSlice = createSlice({
  name: "toggle",
  initialState,
  reducers: {
    setToggle: (state, action) => {
      state.toggle = action.payload;
    },
    setDrawer: (state, action) => {
      state.drawer = action.payload;
    },
    setTickerQuote: (state, action) => {
      state.ticker = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickerQuote.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.ticker = action.payload;
      })
      .addCase(getTickerQuote.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getTickerQuote.rejected, (state, action) => {
        state.status = "failed";
      });
  },
});

export const { setToggle, setTickerQuote, setDrawer } = toggleSlice.actions;
export const toggleReducer = toggleSlice.reducer;
