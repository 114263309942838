import React, { useEffect, useState } from "react";
import apiClient from "../utils/axiosInstance";
import { useAppSelector } from "../store";

function Preview({ imageUrl, hoveredIndex, index }) {
  const { toggleState } = useAppSelector((state) => ({
    toggleState: state.toggle.toggle,
  }));
  const [sourceInfo, setSourceInfo] = useState(null);

  useEffect(() => {
    const getSourceInfo = async (source) => {
      try {
        const response = await apiClient().post(
          `${process.env.REACT_APP_API_URL}/link-preview`,
          { url: source }
        );
        const data = response.data;
        setSourceInfo(data);
      } catch (error) {
        console.error("Error fetching source info:", error);
      }
    };

    getSourceInfo(imageUrl);
  }, [imageUrl]);

  return (
    <>
      {sourceInfo && (
        <>
          <img
            src={sourceInfo?.favicon}
            alt="favicon"
            width={20}
            height={20}
            className="self-start mr-3 mt-1"
          />
          {hoveredIndex === index && sourceInfo?.description ? (
            <div
              className={`absolute top-full left-1/2 z-10 transform -translate-x-1/2 ${
                !toggleState ? "w-96" : "w-96"
              } bg-white p-2 rounded-md shadow-2xl`}
            >
              <div>
                <p className="text-secondary text-md font-medium p-2 overflow-auto max-h-48">
                  {sourceInfo?.description}
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
}

export default Preview;
