import React, { useEffect, useState } from "react";
import Section2 from "../assets/section2.png";
import IconRight from "../assets/icon-park--right.png";
import Section3 from "../assets/section3.png";
import Section4 from "../assets/section4.png";
import { Icon } from "@iconify/react/dist/iconify.js";
import Carousel from "../Components/landing/Carousel";
import { benefits } from "../utils/static";
import Rotate from "../assets/rotate.png";
import Pricing from "../Components/landing/Pricing";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../Components/HomePage/Header";
import Footer from "../Components/HomePage/Footer";

function Home() {
  const navigate = useNavigate();
  const [investmentImage, setInvestmentImage] = useState(false);

  const redirectButton = () => {
    navigate("/auth");
  };

  const handleResize = () => {
    setInvestmentImage(window.innerWidth < 1024);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      navigate("/dashboard");
    }
  }, []);

  const state = useLocation().state;

  return (
    <div className="overflow-x-hidden">
      <Header toPricing={state?.toPricing} />
      <div className="w-full flex justify-center">
        <div className="Container">
          <div className="p-14 text-center">
            <p className="text-[40px] md:text-[59px] font-normal">
              AI Assistant for
              <span className="md:block font-semibold text-lendingButton">
                Equity Researchers
              </span>
            </p>
            <p className="text-xl font-normal mt-4 text-pricingText">
              Get precise answers on US public companies, SEC filings, earnings
              transcripts,
              <span className="md:block">market data, web, and more.</span>
            </p>
          </div>
          <div className="text-center">
            <button
              className="w-[180px] h-[50px] bg-lendingButton rounded-[20px] px-8 py-3 text-white"
              onClick={redirectButton}
            >
              Sign Up
            </button>
          </div>
          <div className="flex justify-center">
            <div className=" shadow-t-2xl mt-10 px-8  rounded-[23px]">
              <img src={Section2} alt="section2" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="Container bg-white">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8 mt-0 md:mt-16 lg:mt-16 mb-10 md:mb-20 lg:mb-20 ">
            <div className="col-span-1 px-5 py-8 md:py-10 xl:py-8 md:px-10  lg:px-10 xl:px-0">
              <div className="">
                <p className="font-semibold text-[40px]">
                  Why Choose &nbsp;
                  <span className="text-lendingButton tracking-widest">
                    ASKFIN
                  </span>
                  ?
                </p>
                <p className="text-pricingText">
                  Purpose-built for Institutional Investors
                </p>
              </div>
              <div className="py-6">
                <p className="font-normal text-2xl">
                  Answers from Verified Sources
                </p>
                <p className="text-pricingText font-normal text-base">
                  Search across SEC filings (10-K, 10-Q), earnings transcripts,
                  market data, Web or a Webpage.
                </p>
              </div>
              <div className="py-6 border-y-2">
                <div className="flex items-center text-lendingButton">
                  <img src={IconRight} alt="icon-right" className="h-6 w-6" />
                  <p className="font-normal text-2xl">Comprehensive Data</p>
                </div>
                <p className="text-pricingText font-normal text-base">
                  Search across SEC filings (10-K, 10-Q), earnings transcripts,
                  market data, Web or a Webpage.
                </p>
              </div>
              <div className="py-6 border-y-1">
                <p className="font-normal text-2xl">Chat with own Documents</p>
                <p className="text-pricingText font-normal text-base">
                  Upload your documents on our encrypted cloud storage and get
                  accurate answers without manual searching.
                </p>
              </div>
              <div className="py-6 border-t-2">
                <p className="font-normal text-2xl">Powerful AI Models</p>
                <p className="text-pricingText font-normal text-base">
                  Powered by advanced AI models such as GPT-4o and Gemini 1.5
                  Pro for unparalleled accuracy and speed.
                </p>
              </div>
            </div>
            <div className="col-span-1 px-5 md:py-10 md:px-10">
              <img src={Section3} alt="section3" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center bg-[#F4F4F4]">
        <div className="Container">
          <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-4 py-10 md:py-10 xl:py-20 items-center">
            <div className="col-span-1 px-5 py-0 md:py-10 md:px-10 xl:px-0">
              <p className="font-semibold text-[40px]">
                See&nbsp;
                <span className="text-lendingButton tracking-widest">
                  ASKFIN&nbsp;
                </span>
                in Action
              </p>
              <p className="font-normal text-base  text-pricingText mt-4">
                Watch how AskFin simplifies stock analysis and decision-making
              </p>
              <p className="text-pricingText font-normal text-base md:text-lg mt-6 md:mt-10">
                Our demo video showcases AskFin's real-time data analysis and
                personalized insights, empowering confident investment
                decisions. See how AskFin revolutionizes stock market research.
                Watch now.
              </p>
            </div>

            <div className="col-span-1 px-5 py-0 md:py-10 md:px-10 ">
              <img src={Section4} alt="section4" className="w-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center bg-lendingButton">
        <div className="Container">
          <div className="px-5 xl:px-0 md:px-10 py-10 md:py-10 lg:py-28">
            <div className="text-white">
              <p className="font-medium text-[40px]">Benefits</p>
              <p className="font-normal text-base">
                Purpose-built for Institutional Investors
              </p>
            </div>

            <div className="md:flex md:justify-center">
              <div className="mt-10">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-10">
                  {benefits.map((benefit, index) => (
                    <div
                      key={index}
                      className="bg-white text-textPrimary p-6 rounded-[20px]"
                    >
                      <Icon icon={benefit.icon} className="h-5 w-5" />
                      <p className="font-normal text-2xl mt-1">
                        {benefit.title}
                      </p>
                      <p className="font-normal text-sm text-pricingText">
                        {benefit.description}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="text-center mt-6">
                  <button
                    className="w-[180px] p-14 bg-white rounded-[20px] px-8 py-3 text-textPrimary"
                    onClick={redirectButton}
                  >
                    Sign Up
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center bg-[#F4F4F4]">
        <div className="Container">
          <Carousel />
        </div>
      </div>
      <div className="w-full flex justify-center" id="pricing-table">
        <div className="Container">
          <div className="bg-[#FFFFFF] py-10">
            <div>
              <p className="font-semibold text-[40px] text-center">Pricing</p>
            </div>
            <div className="px-6 md:py-10">
              <Pricing />
            </div>
            <div className="flex item-center flex-col md:flex-col px-8 md:px-10 mt-10 py-7 border-y-[1px] border-[#ECECEC]">
              <div className="text-center md:text-start">
                <p className="text-[30px] md:text-[40px] font-normal">
                  <span className="font-semibold">Custom plan.</span> Reach out
                  for a custom quote.
                </p>
                <p className="text-pricingText font-normal text-base mt-2">
                  API Access, Unlimited documents, Custom deployment
                </p>
              </div>
              <div className="text-center mt-5 md:mt-10">
                <button className="w-[180px] p-14 rounded-[20px] px-8 py-3 text-white bg-lendingButton">
                  Contact us
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex items-center flex-col md:flex-col lg:flex-row bg-lendingButton ${!investmentImage ? "rotateImage" : ""
          }`}
      >
        <div className="w-full flex justify-center">
          <div className="Container">
            <div className="flex items-center justify-start px-4 py-5 md:py-10 md:px-10">
              <div className="text-center md:text-start">
                <p className="text-[#FFFFFF] text-[40px] font-semibold">
                  Ready to Transform Your <br />
                  Investment Analysis?
                </p>
                <p className="text-[#FFFFFF] text-base font-normal">
                  Free of charge to try out today.
                </p>
                <button
                  className="w-[180px] mt-6 p-14 bg-white rounded-[20px] px-8 py-3 text-textPrimary"
                  onClick={redirectButton}
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
        {investmentImage && (
          <div className="relative flex items-center justify-end top-0 w-full right-0 rounded-[22px] lg:hidden xl:hidden mt-8 md:mt-0">
            <img src={Rotate} alt="Rotate" className="md:w-[767px]" />
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Home;
