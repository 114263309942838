import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import apiClient from "../utils/axiosInstance";
import SpinnerLoading from "./SpinnerLoading";
import { toast } from "react-toastify";

function NewPasswordModal({ newPasswordModal, setNewPasswordModal }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const updatePassword = await apiClient().put("/update-password", {
        password: currentPassword,
        newPassword: newPassword,
      });
      if (updatePassword.data) {
        setNewPasswordModal(false);
        setCurrentPassword("");
        setNewPassword("");
      }
    } catch (error) {
      console.log("error ", error);
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    setNewPasswordModal(false);
    setCurrentPassword("");
    setNewPassword("");
  };

  return (
    <>
      {isLoading && <SpinnerLoading isLoading={isLoading} />}
      {newPasswordModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl w-[350px] md:w-[600px]">
            <div className="p-6">
              <div className="flex items-center justify-between">
                <h2 className="text-2xl font-bold mb-4">Update Password</h2>
                <button onClick={closeModal} className="py-2 px-4">
                  <Icon icon="iconamoon:close" width="1.5em" height="1.5em" />
                </button>
              </div>
              <form onSubmit={handleSubmit} className="mt-5 bg-white">
                <div className="mb-4">
                  <label
                    htmlFor="currentPassword"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    Current Password
                  </label>
                  <input
                    id="currentPassword"
                    type="password"
                    value={currentPassword}
                    onChange={handleCurrentPasswordChange}
                    className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter current password"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="newPassword"
                    className="block text-gray-700 font-medium mb-2"
                  >
                    New Password
                  </label>
                  <input
                    id="newPassword"
                    type="password"
                    value={newPassword}
                    onChange={handleNewPasswordChange}
                    className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Enter new password"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full mt-8 bg-lendingButton text-white font-medium py-2 px-4 rounded-md"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NewPasswordModal;
