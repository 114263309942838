import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../store";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import apiClient from "../../utils/axiosInstance";
import { useNavigate } from "react-router-dom";
import UploadDocumentFile from "../sidebar/UploadDocumentFile";
import useAuth from "../../utils/useAuth";
import { getDocuments, removeDocument } from "../../store/DocumentSlice";
import Modal from "../Modal";
import SpinnerLoading from "../SpinnerLoading";

function Documents({ folder }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [deleteDocumentId, setDeleteDocumentId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDocumentDeleteModal, setIsDocumentDeleteModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [documentIsModal, setDocumentIsModal] = useState(false);
    const { documetState, status } = useAppSelector((state) => ({
        documetState: state.document.documents,
        status: state.document.documentStatus,
    }));

    useEffect(() => {
        setIsLoading(true);
        dispatch(getDocuments(folder.folderId));
        setIsLoading(false);
    }, [folder]);

    const confirmDeleteDocument = async () => {
        try {
            setIsLoading(true);
            const response = await apiClient().delete(`/document/${deleteDocumentId}`);
            dispatch(removeDocument(response.data.documentId));
            setDeleteDocumentId(null);
            setIsDocumentDeleteModal(false);
        } catch (error) {
            console.log("error in delete doc:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteDocument = (documentId) => {
        setIsDocumentDeleteModal(true);
        setDeleteDocumentId(documentId);
    };

    const handleDocument = () => {
        setDocumentIsModal(true);
    };

    const getFileIcon = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        switch (extension) {
            case 'docx':
                return "ph:file-doc-bold";
            case 'pdf':
                return "ph:file-pdf-bold";
            case 'txt':
                return "ph:file-txt-bold";
            case 'jpg':
            case 'jpeg':
                return "ph:file-jpg-bold";
            case 'png':
                return "ph:file-png-bold";
            default:
                return "ph:file-bold";
        }
    };

    const DocumentSkeleton = () => (
        <div className="border-t-2 border-borderColor px-8 py-4 animate-pulse">
            <div className="flex items-center justify-between">
                <div className="bg-gray-200 h-6 w-48 rounded"></div>
                <div className="flex">
                    <div className="bg-gray-200 h-10 w-10 rounded-full mr-2"></div>
                    <div className="bg-gray-200 h-10 w-10 rounded-full"></div>
                </div>
            </div>
            <div className="bg-gray-200 h-5 w-36 rounded mt-3"></div>
        </div>
    );

    return (
        <>
            {isLoading && <SpinnerLoading isLoading={isLoading} />}
            <div className="">
                <div className="flex items-center justify-between px-8 py-6">
                    <div className="flex items-center">
                        <Icon icon="ph:folders" className="mr-2" fontSize={30} />
                        <p className="text-xl text-textPrimary font-medium tracking-wider">
                            {status === "loading" ? (
                                <div className="bg-gray-200 h-6 w-36 rounded animate-pulse"></div>
                            ) : (
                                documetState?.folder?.folderName
                            )}
                        </p>
                    </div>
                    {folder.createdBy === user?.userId && <button
                        className="text-md text-textPrimary font-medium border-black border-2 p-2 rounded-full tracking-wider px-3"
                        onClick={handleDocument}
                    >
                        + Add Documents
                    </button>}

                    <div className="close-btn md:hidden" >
                        <button>
                            <Icon
                                icon="carbon:close"
                                className="w-6 h-6 text-textPrimary"
                                onClick={() => navigate('/dashboard')}
                            />
                        </button>
                    </div>
                </div>

                <div className="overflow-y-scroll" style={{ height: "calc(100vh - 140px)" }}>
                    {status === "loading" ? (
                        <>
                            <DocumentSkeleton />
                            <DocumentSkeleton />
                            <DocumentSkeleton />
                        </>
                    ) : documetState?.documents?.length === 0 ? (
                        <div className="flex flex-col items-center justify-center h-full">
                            <Icon icon="mdi:file-document-outline" className="text-6xl text-gray-400 mb-4" />
                            <p className="text-xl text-gray-500 mb-4">No documents available</p>
                        </div>
                    ) : (
                        documetState?.documents?.map((item) => {
                            const formattedDate = moment(item.createdAt).fromNow();
                            return (
                                <div key={item.documentId} className="">
                                    <div className="border-t-2 border-borderColor">
                                        <div className="px-8 text-lg flex items-center justify-between text-textPrimary font-medium mb-3 mt-3 relative">
                                            <p className="text-lg text-textPrimary flex items-center font-medium mb-1 relative">
                                                <Icon
                                                    icon={getFileIcon(item.documentName)}
                                                    className="text-2xl mr-2"
                                                />
                                                {item.documentName}
                                            </p>
                                            <div className="px-8 flex items-center">
                                                <button
                                                    className="mr-2 hover:bg-gray-300 rounded-3xl p-2 hover:text-lendingButton"
                                                    onClick={() => navigate(`/document-search/${item.documentId}`)}
                                                >
                                                    <Icon icon="ic:outline-message" className="text-xl" />
                                                </button>
                                                {folder.createdBy === user?.userId && <button
                                                    className="text-red-700 font-medium hover:text-red-400 rounded-3xl"
                                                    onClick={() => deleteDocument(item.documentId)}
                                                >
                                                    <Icon icon="fluent:delete-24-regular" className="text-xl" />
                                                </button>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center mt-3 border-borderColor px-8">
                                        <p className="flex items-center mb-3">
                                            <Icon icon="wi:time-4" className="text-3xl text-secondary mr-2" />
                                            {formattedDate}
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    )}
                </div>
            </div>
            <Modal
                showModal={isDocumentDeleteModal}
                setShowModal={setIsDocumentDeleteModal}
                confirmAction={confirmDeleteDocument}
                label={"Delete Document"}
                description={"Are you sure you want to delete this document?"}
                confirmText={"Confirm"}
                cancelText={"Cancel"}
            />
            <UploadDocumentFile
                documentIsModal={documentIsModal}
                setDocumentIsModal={setDocumentIsModal}
                setSelectedFile={setSelectedFile}
                selectedFile={selectedFile}
                documetState={documetState}
                folderId={folder.folderId}
                user={user}
            />
        </>
    );
}

export default Documents;