import SpinnerLoading from "../Components/SpinnerLoading";
import Sidebar from "../Components/Sidebar";
import ChatLoader from "../Components/ChatLoader";
import DocQuestion from "../Components/DocumentSearch/docQuestion";
import PDFViewer from "../Components/DocumentSearch/PDFViewer";
import DocChatBox from "../Components/DocumentSearch/docChatBox";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { useParams } from "react-router-dom";
import useAuth from "../utils/useAuth";
import DashboardSidebar from "./DashboardSidebar";
import { getDocumentChats, resetDocumentChats } from "../store/DocumentSlice";
import { Icon } from "@iconify/react/dist/iconify.js";

function DocumentSearch() {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const lastNodeRef = useRef();
  const params = useParams();
  const { documentId } = params;
  const [isLoading, setIsLoading] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const { status, documentChats } = useAppSelector((state) => ({
    status: state.document.documentChatStatus,
    documentChats: state.document.documentChats,
  }));

  useEffect(() => {
    const getChats = async () => {
      dispatch(getDocumentChats(documentId));
    };
    if (user) {
      getChats();
    }
    return () => {
      dispatch(resetDocumentChats())
    }
  }, [documentId, user]);

  useEffect(() => {
    if (lastNodeRef.current) {
      lastNodeRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [documentChats, question]);


  return (
    <>
      {(status === "loading" || isLoading) && (
        <SpinnerLoading isLoading={true} />
      )}
      <div>
        <div className="flex md:flex-row flex-col">
          <DashboardSidebar />
          <Sidebar />
          <div className="bg-backgroundColor header-height flex justify-center items-center md:px-0 px-2 my-pb-20">
            <div className=" rounded-lg sm:w-full md:w-full lg:w-full">
              <div className="relative">
                <div className="header-height overflow-y-hidden lg:h-[100%] xl:h-[100vh] w-full">
                  <div className="grid grid-cols-1 xl:grid-cols-6 gap-4">
                    <div className="col-span-1 lg:col-span-3 border-r-2 w-full border-borderColor header-height-1 overflow-y-scroll flex flex-col justify-between">
                      <div className={`${!documentChats?.chats?.length > 0 ? "header-height" : ""}`} >
                        {
                          documentChats?.chats?.length === 0 ? (
                            <div className="flex flex-col items-center justify-center h-full">
                              <Icon icon="mdi:chat-alert" className="text-6xl text-gray-400 mb-4" />
                              <p className="text-xl text-gray-500 mb-4">No chats available</p>
                            </div>
                          ) : (documentChats?.chats?.map((item) => {
                            return (
                              <div>
                                <DocQuestion
                                  item={item}
                                  setIsLoading={setIsLoading}
                                  setQuestion={setQuestion}
                                  setIsChatLoading={setIsChatLoading}
                                />
                              </div>
                            );
                          }))
                        }
                        <div>
                          {question && (
                            <div className="px-4 py-2 break-words mt-5 mb-8">
                              <p className="text-lg font-normal text-textPrimary">
                                {question}
                              </p>
                            </div>
                          )}
                          {isChatLoading && <ChatLoader />}
                        </div>
                      </div>
                      <div ref={lastNodeRef}></div>
                      <DocChatBox
                        documentId={documentId}
                        setQuestion={setQuestion}
                        setIsChatLoading={setIsChatLoading}
                      />
                    </div>
                    <div className="col-span-1 lg:col-span-3 p-4 header-height w-full  flex">
                      {documentChats && <PDFViewer doc={documentChats?.document} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentSearch;
