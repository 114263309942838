import { Icon } from "@iconify/react/dist/iconify.js";
import { useAppDispatch, useAppSelector } from "../store";
import { EmptyState, MessageList, MessageSkeleton } from "./SharedMessageComponents";
import { ContentState, EditorState } from "draft-js";
import { useEffect, useRef, useState } from "react";
import MessageEditor from "./MessageEditor";
import { v4 as uuidv4 } from "uuid";
import apiClient from "../utils/axiosInstance";
import { addReplyMessage, removeReplyMessage, updateReplyAiMessage, updateReplyDirectMessageCounter, updateReplyMessage, updateReplyMessageCounter } from "../store/ThreadSlice";
import { toast } from "react-toastify";

function ReplyMessages({ isReplying, setIsReplying, sharedFolders, user, socket, tab, id, notification }) {
    const { replyMessage, replyMessageStatus } = useAppSelector((state) => ({
        replyMessage: state.thread.replyMessage,
        replyMessageStatus: state.thread.replyMessageStatus,
    }));
    const messagesEndRef = useRef(null);
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [isApiCalling, setIsApiCalling] = useState(false);
    const [mentionState, setMentionState] = useState(() => ContentState.createFromText(""));
    const [taggedFolders, setTaggedFolders] = useState([]);
    const dispatch = useAppDispatch();
    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [replyMessage, scrollToBottom]);

    useEffect(() => {
        if (!user || !socket) return;
        let event = "";
        const handleNewMessage = ({ message }) => {
            dispatch(addReplyMessage(message));
            if (replyMessage?.replies.length === 0) {
                const data = {
                    tab: tab,
                    messageId: replyMessage.messageId,
                    profilePicturesUsers: message.profilePicturesUsers
                }
                dispatch(updateReplyMessage(data))
            } else {
                let data = {
                    messageId: message.messageId,
                    profilePicturesUsers: message.profilePicturesUsers
                }
                if (tab === "dm") {
                    dispatch(updateReplyDirectMessageCounter(data));
                } else {

                    dispatch(updateReplyMessageCounter(data));
                }
            }
            scrollToBottom();
            setEditorState(EditorState.createEmpty());
            setIsApiCalling(false);
        };

        if (tab === "team") {
            event = "receive_space_reply_message";
        } else if (tab === "thread") {
            event = "receive_main_thread_reply_message";
        } else if (tab === "dm") {
            event = "receive_direct_reply_message";
        }

        socket.on(event, handleNewMessage);
        return () => {
            socket.off(event, handleNewMessage);
        };
    }, [id, user, socket, dispatch, scrollToBottom]);


    const sendMessage = async (messageData) => {
        let replyId = uuidv4();
        const data = {
            message: messageData,
        };
        let event = "";

        if (tab === "team") {
            data.room = `space-room-${id}`;
            data.teamId = id;
            data.teamName = notification;
            event = "new_space_reply_message";
        } else if (tab === "thread") {
            data.room = id;
            data.threadId = id;
            data.threadName = notification;
            event = "new_main_thread_reply_message";
        } else if (tab === "dm") {
            data.receiverId = id;
            event = "direct_reply_message";
        }

        if (mentionState.askFinTagged || messageData.message.includes("@askfin")) {
            dispatch(addReplyMessage(messageData));
            const tempAiData = {
                createdAt: new Date().toISOString(),
                answer: {
                    answers: [
                        {
                            answer: "askfin is thinking...",
                        }
                    ]
                },
                isAiChat: true,
                messageType: "text",
                receivers: messageData.tag,
                senderId: messageData.senderId,
                senderName: messageData.senderName,
                threadId: id,
                replyId: replyId,
            }
            dispatch(addReplyMessage(tempAiData));
        } else {
            dispatch(addReplyMessage(messageData));
        }
        setEditorState(EditorState.createEmpty());
        setSelectedFiles([]);
        scrollToBottom();

        try {
            const apiMessageData = {
                replyId: messageData.replyId,
                messageId: messageData.messageId,
                tag: messageData.tag,
                createdAt: messageData.createdAt,
                messageType: messageData.messageType,
                tab: tab,
                isAiChat: false
            };

            if (messageData.messageType === "text" || messageData.messageType === "text-file") {
                apiMessageData.message = messageData.message;
            }

            if (messageData.messageType === "file" || messageData.messageType === "text-file") {
                apiMessageData.files = messageData.files;
            }

            const res = await apiClient().post("/reply-message", apiMessageData);
            if (replyMessage?.replies.length === 0) {
                const data = {
                    tab: tab,
                    messageId: replyMessage.messageId,
                    profilePicturesUsers: res.data.reply.profilePicturesUsers
                }
                dispatch(updateReplyMessage(data))
            }
            data.message = {
                ...data.message,
                profilePicturesUsers: res.data.reply.profilePicturesUsers
            };
            socket.emit(event, data);
            if (tab === "dm") {
                const data = {
                    messageId: replyMessage.messageId,
                    profilePicturesUsers: res.data.reply.profilePicturesUsers
                }
                dispatch(updateReplyDirectMessageCounter(data))
            } else {
                const data = {
                    messageId: replyMessage.messageId,
                    profilePicturesUsers: res.data.reply.profilePicturesUsers
                }
                dispatch(updateReplyMessageCounter(data));
            }
            if (mentionState.askFinTagged || messageData.message.includes("@askfin")) {
                if (taggedFolders.length > 0) {
                    try {
                        const payload = {
                            threadId: id,
                            folderIds: taggedFolders,
                            query: messageData.message,
                            replyId: replyId,
                            messageId: replyMessage.messageId,
                            createdAt: new Date().toISOString(),
                            senderId: messageData.senderId,
                            receivers: messageData.tag,
                            type: "thread",
                        }
                        const response = await apiClient().post("/chatwithfolder", payload);
                        const answer = {
                            answers: response.data.response
                        }
                        dispatch(updateReplyAiMessage({ replyId: replyId, answer: answer }));
                    } catch (error) {
                        dispatch(removeReplyMessage(replyId));
                        toast.error(error);
                    }
                } else {
                    try {

                        let body = {
                            replyId: replyId,
                            messageId: replyMessage.messageId,
                            query: messageData.message.replace("@askfin", "").trim(),
                            action: "chat",
                            createdAt: new Date().toISOString(),
                            threadId: id,
                            tag: messageData.tag,
                            isAiChatReply: true,
                            isReplyInDm: tab === "dm" ? true : false,
                        };
                        const response = await apiClient().post(`/chat`, body);
                        dispatch(updateReplyAiMessage({ replyId: replyId, answer: response.data.answer }));
                    } catch (error) {
                        dispatch(removeReplyMessage(replyId));
                        toast.error(error);
                    }
                }
            }
        } catch (error) {
            console.error("Error posting message:", error);
        }
    };

    const handleSendMessage = async (editorState) => {
        let message = "";
        const hasText = editorState.getCurrentContent().hasText();
        const hasFiles = selectedFiles.length > 0;

        if ((!hasText && !hasFiles) || uploadingFiles.length > 0) return;

        setIsApiCalling(true);
        message = editorState.getCurrentContent().getPlainText();
        const baseMessageData = {
            replyId: uuidv4(),
            messageId: replyMessage.messageId,
            isThread: false,
            tag: tab === "dm" ? [{
                accountType: "member",
                tagId: id
            }] : replyMessage?.receivers,
            createdAt: new Date().toISOString(),
            senderId: user?.userId,
            senderName: user?.name,
        };

        let messageData;

        if (hasText && hasFiles) {
            messageData = {
                ...baseMessageData,
                message: message,
                messageType: "text-file",
                files: selectedFiles.map((file) => ({
                    docS3Loc: file.docS3Loc,
                    url: file.url,
                    fileName: file.file.name,
                })),
            };
        } else if (hasText) {
            messageData = {
                ...baseMessageData,
                message: message,
                messageType: "text",
            };
        } else if (hasFiles) {
            messageData = {
                ...baseMessageData,
                messageType: "file",
                files: selectedFiles.map((file) => ({
                    docS3Loc: file.docS3Loc,
                    url: file.url,
                    fileName: file.file.name,
                })),
            };
        }

        await sendMessage(messageData);
        setIsApiCalling(false);
    };

    return (
        <div className={`flex flex-col w-0 md:w-[42%] header-height-1 border-2 rounded-lg m-2`}>
            <div className={`border-b-2 m-2 md:p-2 rounded transition-all duration-300 ease-in-out flex items-center justify-between`}>
                <h1 className="flex items-center text-xl font-semibold">
                    Thread
                </h1>
                <button className="close-btn" onClick={() => setIsReplying(false)}>
                    <Icon icon="carbon:close" className="w-6 h-6 text-textPrimary" />
                </button>
            </div>
            <div className="flex-1 flex flex-col overflow-hidden bg-white">
                <div className="flex-1 overflow-y-auto px-0 lg:px-4 ">
                    {replyMessageStatus === "loading" ? (
                        <>
                            <MessageSkeleton isReplying={isReplying} />
                            <MessageSkeleton isReplying={isReplying} />
                            <MessageSkeleton isReplying={isReplying} />
                        </>
                    ) : replyMessage ? (
                        <>
                            <MessageList messages={[replyMessage]} isReplying={isReplying} setIsReplying={setIsReplying} isDisplayReplies={false} />
                            {replyMessage?.hasReplies && <div className="flex items-center">
                                <p className="w-[15%]">{replyMessage?.replyCounter} {replyMessage?.replyCounter == 1 ? "reply" : "replies"}</p>
                                <hr className="w-[85%]" />
                            </div>}
                            <MessageList messages={replyMessage?.replies} isReplying={isReplying} setIsReplying={setIsReplying} isDisplayReplies={false} />
                        </>
                    ) : (
                        <EmptyState />
                    )}
                    <div ref={messagesEndRef} />
                </div>
                <MessageEditor
                    handleSendMessage={handleSendMessage}
                    isApiCalling={isApiCalling}
                    selectedFiles={selectedFiles}
                    setSelectedFiles={setSelectedFiles}
                    uploadingFiles={uploadingFiles}
                    setUploadingFiles={setUploadingFiles}
                    mentionState={mentionState}
                    setMentionState={setMentionState}
                    taggedFolders={taggedFolders}
                    setTaggedFolders={setTaggedFolders}
                    editorState={editorState}
                    setEditorState={setEditorState}
                    sharedFolders={sharedFolders}
                    isReplying={isReplying}
                />
            </div>
        </div>
    )
}

export default ReplyMessages;