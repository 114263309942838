import { Icon } from "@iconify/react";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import clipboardCopy from "clipboard-copy";
import { useAppDispatch } from "../../store";
import ReportModal from "../ReportModal";
import ShareModal from "../ShareModal";
import { documentChat, likedDocumentChat } from "../../store/DocumentSlice";
import apiClient from "../../utils/axiosInstance";
import Markdown from "react-markdown";

function DocQuestion({
  item,
  setIsLoading,
  share,
  setQuestion,
  setIsChatLoading,
}) {
  const dispatch = useAppDispatch();
  const [copy, setCopy] = useState(false);
  const [isShareModal, setIsShareModal] = useState();
  const [copyText, setCopyText] = useState("");
  const [isReportModal, setIsReportModal] = useState(false);

  const handleRelated = async (data) => {
    setIsChatLoading(true);
    setQuestion(data);
    try {
      const body = {
        query: data,
        documentId: item.threadId,
        createdAt: new Date().toISOString(),
      };
      const response = await apiClient().post(`/chat-doc`, body);
      dispatch(documentChat(response.data.response));
      setQuestion("");
    } catch (error) {
      console.log("error in chat slice:", error);
      toast.error(error);
    } finally {
      setQuestion("");
      setIsChatLoading(false);
    }
  };

  const handleShare = async () => {
    setIsShareModal(true);
    try {
      setIsLoading(true);
      const body = {
        action: "share",
        threadId: item.threadId,
      };
      await apiClient().post("/chat", body);
      const pageURL = `${window.location.host}/share-doc-chat/${item.threadId}`;
      setCopyText(pageURL);
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLike = async (liked) => {
    if (liked == 1) {
      return;
    }
    try {
      setIsLoading(true);
      const body = {
        action: "like",
        messageId: item.messageId,
      };
      await apiClient().post(`/chat`, body);
      dispatch(likedDocumentChat(item.messageId));
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (copy) {
      setTimeout(() => {
        setCopy(false);
      }, 1000);
    }
  }, [copy]);

  const handleCopyAnswer = () => {
    const answerText = item.answer.answer;
    if (answerText) {
      clipboardCopy(answerText)
        .then(() => {
          setCopy(true);
        })
        .catch((error) => {
          // Handle any errors
        });
    }
  };

  return (
    <>
      <div className="px-4 py-2">
        <div className="break-words mb-6">
          <p className="text-xl mt-4 font-normal text-textPrimary">
            {item?.query}
          </p>
        </div>

        <div className="break-words border border-allBorderColor p-5 mb-5 rounded-lg bg-white">
          <p className="text-textPrimary flex items-center text-xl font-medium mb-2">
            <Icon icon="gridicons:menus" className="text-2xl mr-2" />
            Answer
          </p>

          <div>
            <p className="text-md text-secondary font-normal relative">
              <Markdown>{item?.answer?.answer}</Markdown>
            </p>
          </div>

          {!share && (
            <div className="flex items-center justify-between">
              <div className="flex items-center mt-4">
                {/* <button
                  onClick={handleShare}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor px-2 py-1  rounded-full"
                >
                  <Icon
                    icon="ph:share-fat"
                    className="text-xl mr-1 text-[#AEAEAE]"
                  />
                  Share
                </button> */}
                <button
                  onClick={handleCopyAnswer}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-1 rounded-full"
                >
                  <Icon
                    icon={copy ? "uit:check" : "icon-park-outline:clipboard"}
                    className={`text-xl mr-1 ${copy ? "text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Copy
                </button>
              </div>
              <div className="flex items-center mt-4">
                <button
                  onClick={() => {
                    handleLike(item?.liked);
                  }}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-0.5 rounded-full"
                >
                  <Icon
                    icon="ei:like"
                    className={`text-3xl mr-1 ${item?.liked == 1 ? "text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Like
                </button>
                <button
                  onClick={() => setIsReportModal(!isReportModal)}
                  className="flex items-center text-sm font-normal text-textPrimary hover:bg-allBorderColor ml-2 px-2 py-1 rounded-full"
                >
                  <Icon
                    icon="solar:dislike-broken"
                    className={`text-2xl mr-1 ${item?.report == 1 ? "text-black" : "text-[#AEAEAE]"
                      }`}
                  />
                  Report
                </button>
              </div>
            </div>
          )}
        </div>
        {item?.answer?.questions.length > 0 && (
          <div className="mt-5 border border-allBorderColor p-5 mb-5 rounded-lg bg-white">
            <p className="flex items-center text-md font-medium">
              <Icon icon="bx:layer-plus" className="text-xl mr-2" />
              Related
            </p>
            {item?.answer?.questions?.map((data, index) => {
              return (
                <div className="mt-4 w-full" key={index}>
                  <button
                    onClick={() => handleRelated(data)}
                    className="text-[#153CC5] border w-full text-sm border-[#153CC5] p-2.5 hover:bg-[#153cc533] rounded-lg"
                  >
                    {data}
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <ShareModal
        item={item}
        isShareModal={isShareModal}
        setIsShareModal={setIsShareModal}
        copyText={copyText}
        setCopyText={setCopyText}
      />

      {item?.report !== 1 && (
        <ReportModal
          isReportModal={isReportModal}
          setIsReportModal={setIsReportModal}
          messageId={item?.messageId}
          type={"doc-chat"}
        />
      )}
    </>
  );
}

export default DocQuestion;
