import Sidebar from "../Components/Sidebar";
import History from "../Components/ChatHistory/history";
import DashboardSidebar from "./DashboardSidebar";

function ChatHistory() {
  return (
    <>
      <div>
        <div className="flex md:flex-row flex-col">
          <DashboardSidebar />
          <Sidebar />
          <div className="bg-backgroundColor  header-height flex justify-center items-center md:px-0 px-2 my-pb-20">
            <div className=" rounded-lg sm:w-full md:w-full lg:w-full">
              <div className="relative">
                <div className="header-height overflow-y-hidden lg:h-[100%] xl:h-[100vh] w-full">
                  <History />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatHistory;
