import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react/dist/iconify.js";
import Logo from "../../assets/logo.png";

function Header({ toPricing }) {
  const navigate = useNavigate();
  const [plansDropDown, setPlansDropDown] = useState(false);

  const redirectButtonToSignUp = () => {
    navigate("/auth");
  };

  const redirectButtonToSignIn = () => {
    navigate("/auth", {
      state: { toLogin: true }
    });
  };

  const toggleDropdown = () => {
    setPlansDropDown(!plansDropDown);
  };

  const scrollToPricing = () => {
    document
      .getElementById("pricing-table")
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (toPricing) {
      scrollToPricing();
    }
  }, [toPricing]);

  return (
    <div className="w-full flex justify-center">
      <div className="w-[1200px]">
        <div className="flex items-center justify-between py-6 lg:px-10">
          <div className="flex cursor-pointer" onClick={() => navigate("/")}>
            <img src={Logo} alt="logo" className="h-8 w-8 mr-2 md:ml-0 ml-2" />
            <p className="text-2xl tracking-widest font-semibold">
              ASKFIN
            </p>
          </div>
          <div className="hidden md:flex ">
            <button
              className="md:ml-12 ml-10 text-base font-normal text-landingText"
              onClick={scrollToPricing}
            >
              Plans
            </button>
            <button
              className="md:ml-12 ml-6 text-base font-normal text-landingText"
              onClick={redirectButtonToSignIn}
            >
              Sign In
            </button>
          </div>
          <div className="md:hidden flex items-center mr-11">
            <button onClick={toggleDropdown} className="focus:outline-none">
              <Icon icon="ic:round-menu" width="1.5em" height="1.5em" />
            </button>
          </div>
          {plansDropDown && (
            <div className="md:hidden absolute right-5 top-12 mt-2 w-48 bg-white rounded-md shadow-lg">
              <button
                className="block px-4 py-2 text-sm font-normal text-landingText hover:bg-borderColor"
                onClick={() => {
                  document
                    .getElementById("pricing-table")
                    .scrollIntoView({ behavior: "smooth" });
                }}
              >
                Plans
              </button>
              <button
                className="block px-4 py-2 text-sm font-normal text-landingText hover:bg-borderColor"
                onClick={redirectButtonToSignUp}
              >
                Sign Up
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
