import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store";
import { getOrganization } from "../store/organization";
import { ContentState, EditorState } from "draft-js";
import useAuth from "../utils/useAuth";
import {
  addNewDirectMessage,
  getDirectMessages,
  removeDirectMessage,
  resetDirectMessages,
  updateDirectMessage,
} from "../store/ThreadSlice";
import { useSocket } from "../context/SocketProvider";
import apiClient from "../utils/axiosInstance";
import DashboardSidebar from "./DashboardSidebar";
import Sidebar from "../Components/Sidebar";
import { Icon } from "@iconify/react/dist/iconify.js";
import {
  MessageList,
  EmptyState,
  MessageSkeleton,
} from "../Components/SharedMessageComponents";
import MessageEditor from "../Components/MessageEditor";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import ReplyMessages from "../Components/ReplyMessages";

function DirectMessage() {
  const { memberId } = useParams();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const messagesEndRef = useRef(null);
  const { socket } = useSocket();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadingFiles, setUploadingFiles] = useState([]);
  const [sharedFolders, setSharedFolders] = useState([]);
  const { organizationState, directMessageState } = useAppSelector((state) => ({
    organizationState: state.organization.organization,
    directMessageState: state.thread.directMessages,
  }));
  const [mentionState, setMentionState] = useState({
    showAskFin: true,
    askFinTagged: false,
  });
  const [taggedFolders, setTaggedFolders] = useState([]);
  const [isReplying, setIsReplying] = useState(false);

  const member =
    organizationState && organizationState.length > 0
      ? organizationState[0]?.members?.find(
        (member) => member.userId === memberId
      )
      : null;

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    const fetchSharedFolders = async () => {
      if (memberId && user) {
        try {
          const payload = {
            senderId: user?.userId,
            receiverId: memberId,
            isForDm: true,
          };
          const response = await apiClient().post(`/get-shared-folders`, payload);
          setSharedFolders(response.data.folders);
        } catch (error) {
          console.error('Error fetching shared folders:', error);
        }
      }
    };
    fetchSharedFolders();
  }, [memberId, user]);

  useEffect(() => {
    if (organizationState?.length === 0) {
      dispatch(getOrganization())
    }
  }, [dispatch, organizationState]);

  useEffect(() => {
    if (!user) return;
    setIsLoadingMessages(true);
    if (!directMessageState.length > 0 && user) {
      dispatch(
        getDirectMessages({ senderId: user?.userId, receiverId: memberId })
      ).finally(() => setIsLoadingMessages(false));
    }
    return () => dispatch(resetDirectMessages());
  }, [memberId, user]);

  useEffect(() => {
    scrollToBottom();
  }, [directMessageState, scrollToBottom]);

  useEffect(() => {
    if (user && socket && memberId) {
      socket.emit("set_dm_availability", { receiverId: memberId });
    }
    return () => {
      if (user && socket && memberId) {
        socket.emit("close_dm_chat", { receiverId: memberId });
      }
    };
  }, [user, socket, memberId]);

  useEffect(() => {
    if (!user || !socket) return;

    const handleNewMessage = ({ message }) => {
      dispatch(addNewDirectMessage(message));
      scrollToBottom();
      setEditorState(EditorState.createEmpty());
      setIsApiCalling(false);
    };

    socket.on("receive_direct_message", handleNewMessage);

    return () => {
      socket.off("receive_direct_message", handleNewMessage);
    };
  }, [memberId, user, socket, dispatch, scrollToBottom]);

  const handleSendMessage = async (editorState) => {
    let message = "";
    const hasText = editorState.getCurrentContent().hasText();
    const hasFiles = selectedFiles.length > 0;

    if ((!hasText && !hasFiles) || uploadingFiles.length > 0) return;

    setIsApiCalling(true);
    message = editorState.getCurrentContent().getPlainText();

    const baseMessageData = {
      createdAt: new Date().toISOString(),
      senderId: user?.userId,
      senderName: user?.name,
      receiverId: memberId,
      isAiChat: false,
    };

    let messageData;

    if (hasText && hasFiles) {
      messageData = {
        ...baseMessageData,
        message: message,
        messageType: "text-file",
        files: selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        })),
      };
    } else if (hasText) {
      messageData = {
        ...baseMessageData,
        message: message,
        messageType: "text",
      };
    } else if (hasFiles) {
      messageData = {
        ...baseMessageData,
        messageType: "file",
        files: selectedFiles.map((file) => ({
          docS3Loc: file.docS3Loc,
          url: file.url,
          fileName: file.file.name,
        })),
      };
    }

    await sendMessage(messageData);
    setIsApiCalling(false);
  };

  const sendMessage = async (messageData) => {
    let messageId = uuidv4();
    const data = {
      senderId: user?.userId,
      receiverId: memberId,
      message: messageData,
    };

    socket.emit("direct_message", data);

    if (mentionState.askFinTagged || messageData.message.includes("@askfin")) {
      //dispatch(addNewDirectMessage(messageData));
      const tempAiData = {
        createdAt: new Date().toISOString(),
        answer: {
          answers: [
            {
              answer: "askfin is thinking...",
            }
          ]
        },
        directMessageId: messageId,
        messageType: "text",
        isAiChat: true,
        receiverId: memberId,
        senderId: user?.userId,
        senderName: user?.name,
      }
      dispatch(addNewDirectMessage(tempAiData));
    }
    // else {
    //   dispatch(addNewDirectMessage(messageData));
    // }

    setEditorState(EditorState.createEmpty());
    setSelectedFiles([]);
    scrollToBottom();

    try {
      const apiMessageData = {
        senderId: user?.userId,
        receiverId: memberId,
        createdAt: new Date().toISOString(),
        messageType: messageData.messageType,
      };

      if (messageData.messageType === "text" || messageData.messageType === "text-file") {
        apiMessageData.message = messageData.message;
      }

      if (messageData.messageType === "file" || messageData.messageType === "text-file") {
        apiMessageData.files = messageData.files;
      }

      const response = await apiClient().post("/direct-message", apiMessageData);
      dispatch(addNewDirectMessage({...response.data.message,senderName:messageData.senderName}))
      if (mentionState.askFinTagged || messageData.message.includes("@askfin")) {
        if (taggedFolders.length > 0) {
          try {
            const response = await apiClient().post("/chatwithfolder", {
              messageId: messageId,
              folderIds: taggedFolders,
              query: messageData.message,
              createdAt: new Date().toISOString(),
              senderId: user?.userId,
              receiverId: memberId,
              type: "direct-messages",
            });
            const answer = {
              answers: response.data.response
            }
            dispatch(updateDirectMessage({ directMessageId: messageId, answer: answer }));
          } catch (error) {
            dispatch(removeDirectMessage(messageId));
            toast.error(error);
          }
        } else {
          try {
            let body = {
              messageId: messageId,
              isForDm: true,
              query: messageData.message.replace("@askfin", "").trim(),
              action: "chat",
              createdAt: new Date().toISOString(),
              senderId: user?.userId,
              receiverId: memberId,
            };

            const response = await apiClient().post(`/chat`, body);
            dispatch(updateDirectMessage({ directMessageId: messageId, answer: response.data.answer }));
          } catch (error) {
            dispatch(removeDirectMessage(messageId));
            toast.error(error);
          }
        }
      }
    } catch (error) {
      console.error("Error posting message:", error);
    }
  };

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleRelatedQueryClick = (query) => {
    setMentionState({ ...mentionState, askFinTagged: true });
    const prefixedQuery = `@askfin ${query}`;
    const newEditorState = EditorState.createWithContent(ContentState.createFromText(prefixedQuery));
    handleSendMessage(newEditorState);
  };

  const navigate = useNavigate()

  return (
    <div className="flex header-heigth-1 bg-backgroundColor">
      <div className="flex md:flex-row flex-col w-full">
        <DashboardSidebar />
        <Sidebar />
        <div className={`flex flex-col ${isReplying ? "md:w-[58%]" : "w-full"} my-pb-20 header-height-1`}>
          <div className={`border-b-2 m-2 ${!isReplying && "xl:px-24"} md:p-2 rounded transition-all duration-300 ease-in-out flex items-center justify-between`}>
            <div className="close-btn md:hidden" >
              <button>
                <Icon
                  icon="carbon:close"
                  className="w-6 h-6 text-textPrimary"
                  onClick={() => navigate('/dashboard')}
                />
              </button>
            </div>
            <h1 className="flex items-center text-xl font-semibold">
              <Icon icon="tdesign:member" width="1.2em" height="1.2em" className="mr-1" />
              {member?.name || "Loading..."}
            </h1>
          </div>
          <div className="flex-1 flex flex-col overflow-hidden bg-white">
            <div className="flex-1 overflow-y-auto px-0 lg:px-4 ">
              {isLoadingMessages ? (
                <>
                  <MessageSkeleton />
                  <MessageSkeleton />
                  <MessageSkeleton />
                </>
              ) : directMessageState.length > 0 ? (
                <MessageList messages={directMessageState} handleRelatedQueryClick={handleRelatedQueryClick} isReplying={isReplying} setIsReplying={setIsReplying} tab="dm" />
              ) : (
                <EmptyState />
              )}
              <div ref={messagesEndRef} />
            </div>
            <MessageEditor
              editorState={editorState}
              setEditorState={setEditorState}
              handleChange={handleChange}
              handleSendMessage={handleSendMessage}
              isApiCalling={isApiCalling}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              uploadingFiles={uploadingFiles}
              setUploadingFiles={setUploadingFiles}
              mentionState={mentionState}
              setMentionState={setMentionState}
              taggedFolders={taggedFolders}
              setTaggedFolders={setTaggedFolders}
              sharedFolders={sharedFolders}
            />
          </div>
        </div>
        {isReplying && <ReplyMessages isReplying={isReplying} setIsReplying={setIsReplying} sharedFolders={sharedFolders} id={memberId} user={user} socket={socket} tab="dm" />}
      </div>
    </div>
  );
}

export default DirectMessage;
