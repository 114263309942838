import React, { useEffect, useState } from "react";

function LinkUrl({
  urlLink,
  worker,
  mainContext,
  scrollId,
  response,
  setResponse,
  isFetched,
  setIsFetched,
}) {
  useEffect(() => {
    const fetchHtml = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/fetch-html`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            url: urlLink,
            mainContext: mainContext,
            worker: worker,
          }),
        });

        if (res.ok) {
          const data = await res.json();
          setResponse(data.html);
        } else {
          setIsFetched(true);
        }
      } catch (error) {
        console.log("error in fetch html:", error);
      } finally {
        setIsFetched(true);
      }
    };

    if (worker && mainContext && urlLink) {
      fetchHtml();
    }
  }, [isFetched, mainContext]);

  useEffect(() => {
    if (scrollId) {
      const scrollToHighlight = () => {
        const element = document.getElementById(scrollId);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };

      scrollToHighlight();
    }
  }, [scrollId]);

  return (
    <div>
      <div className="w-full">
        {!isFetched && <p>Loading...</p>}
        {response && <div dangerouslySetInnerHTML={{ __html: response }}></div>}
      </div>
    </div>
  );
}

export default LinkUrl;
