import { useState } from "react";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import SpinnerLoading from "../SpinnerLoading";
import useAuth from "../../utils/useAuth";
import { useAppDispatch } from "../../store";
import apiClient from "../../utils/axiosInstance";
import NewPasswordModal from "../NewpasswordModal";
import Modal from "../Modal";

const AdminTransferModal = ({ showModal, setShowModal, admins, onConfirm }) => {
    const [selectedAdmin, setSelectedAdmin] = useState('');
    const [warning, setWarning] = useState('');

    const handleConfirm = () => {
        if (selectedAdmin) {
            onConfirm(selectedAdmin);
            setWarning('');
        } else {
            setWarning('Please choose an admin before continuing.');
        }
    };

    const handleCancel = () => {
        setShowModal(false);
        setWarning('');
    };

    if (!showModal) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-md w-full">
                <div className="p-6">
                    <h2 className="text-2xl font-bold mb-4">Transfer Invited Members</h2>
                    <p className="mb-2">Please choose an admin who will pay for your invited members :</p>
                    <select
                        value={selectedAdmin}
                        onChange={(e) => {
                            setSelectedAdmin(e.target.value);
                            setWarning('');
                        }}
                        className="w-full p-2 border rounded"
                    >
                        <option value="">Select an admin</option>
                        {admins?.map(admin => (
                            <option key={admin.userId} value={admin.userId}>
                                {admin.email}
                            </option>
                        ))}
                    </select>
                    {warning && <p className="text-red-500 mt-2">{warning}</p>}
                    <div className="flex justify-end space-x-4 mt-4">
                        <button
                            onClick={handleConfirm}
                            className="bg-lendingButton text-white font-bold py-2 px-4 rounded"
                        >
                            Continue
                        </button>
                        <button
                            onClick={handleCancel}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


function AccountSetting({ isAdmin, userDetailsState, org, user }) {
    const { handleLogOut } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [newPasswordModal, setNewPasswordModal] = useState(false);
    const [showAdminTransferModal, setShowAdminTransferModal] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [showFinalConfirmationModal, setShowFinalConfirmationModal] = useState(false);

    const handleLogoutFun = () => {
        handleLogOut();
    };
    const handleDeleteAccount = () => {
        if (isAdmin) {
            const invitedMembers = org?.members.filter(member => member.invitedBy === user?.userId);
            if (invitedMembers.length > 0) {
                setShowAdminTransferModal(true);
            } else {
                setShowModal(true);
            }
        } else {
            setShowModal(true);
        }
    };

    const handleAdminTransfer = (selectedAdminId) => {
        if (selectedAdminId) {
            setSelectedAdmin(selectedAdminId);
            setShowFinalConfirmationModal(true);
            setShowAdminTransferModal(false);
        }
    };

    const confirmDeleteAccount = async () => {
        try {
            setIsLoading(true);
            const response = await apiClient().post("/account", {
                selectedAdmin: selectedAdmin,
            });
            if (response.data && response.status === 200) {
                posthog.reset(true);
                dispatch(handleLogOut());
                navigate("/");
            }
        } catch (error) {
            toast.error("Error while deleting account");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && <SpinnerLoading isLoading={isLoading} />}
            {(userDetailsState) &&
                <div className="bg-white rounded-lg w-full m-auto">
                    <div className="account-details">
                        <div>
                            <p className="text-lg font-normal mb-3">Account</p>
                        </div>
                        <div className="p-6 rounded-xl bg-backgroundColor">
                            <div className="flex items-center justify-between border-b-2 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">Profile Picture</p>
                                <div className="flex">
                                    {userDetailsState?.userPictureUrl ? (
                                        <img
                                            src={userDetailsState.userPictureUrl}
                                            alt="Profile"
                                            className="rounded-full h-12 w-12 object-cover"
                                        />
                                    ) : (
                                        <div className="bg-lendingButton text-white text-2xl rounded-full h-12 w-12 flex items-center justify-center cursor-pointer">
                                            {userDetailsState?.name?.charAt(0).toUpperCase()}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-center justify-between border-b-2 mt-5 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">UserName</p>
                                <div className="flex">
                                    <p className="text-base font-normal text-textPrimary">
                                        {userDetailsState?.name}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center justify-between border-b-2 border-borderColor mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary">Email</p>
                                <div className="flex">
                                    <p className="text-base font-normal text-textPrimary">
                                        {userDetailsState?.email}
                                    </p>
                                </div>
                            </div>
                            {userDetailsState?.usageType === "business" && <div className="flex items-center justify-between border-b-2 border-borderColor  mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary">Organization Name</p>
                                <div className="flex">
                                    <p className="text-base font-normal text-textPrimary">
                                        {org?.organizationName}
                                    </p>
                                </div>
                            </div>}
                            <div className="flex items-center justify-between mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary">Created on</p>
                                <div className="flex">
                                    <p className="text-base font-normal text-textPrimary">
                                        {format(new Date(userDetailsState?.createdAt), "dd/MM/yyyy")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="account-system mt-8">
                        <div>
                            <p className="text-lg font-normal mb-4">System</p>
                        </div>
                        <div className="md:p-6 p-2 rounded-xl bg-backgroundColor">
                            <div className="flex items-center justify-between border-b-2 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">
                                    Active account
                                </p>
                                <div className="flex">
                                    <button
                                        onClick={handleLogoutFun}
                                        className="flex items-center py-2 px-4  bg-borderColor text-center text-md font-medium rounded-lg text-textPrimary"
                                    >
                                        Logout
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center  justify-between border-b-2 mt-5 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">
                                    Delete account
                                </p>
                                <div className="flex">
                                    <button
                                        onClick={handleDeleteAccount}
                                        className="text-md font-normal bg-borderColor text-textPrimary py-2 px-4 rounded  w-full transition-colors duration-300"
                                    >
                                        Delete Account
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-between mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary">
                                    Update Password
                                </p>
                                <div className="flex">
                                    <button
                                        onClick={() => setNewPasswordModal(!newPasswordModal)}
                                        className=" text-md font-normal bg-borderColor text-textPrimary py-2 mb-4 px-4 rounded w-full transition-colors duration-300"
                                    >
                                        Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                confirmAction={confirmDeleteAccount}
                label={"Delete Account"}
                description={"Are you sure you want to delete your account?"}
                confirmText={"Confirm"}
                cancelText={"Cancel"}
            />

            {showAdminTransferModal && (
                <AdminTransferModal
                    showModal={showAdminTransferModal}
                    setShowModal={setShowAdminTransferModal}
                    admins={org?.members.filter(member => ["admin", "super admin"].includes(member.role) && member.userId !== user?.userId)}
                    onConfirm={handleAdminTransfer}
                />
            )}

            <Modal
                showModal={showFinalConfirmationModal}
                setShowModal={setShowFinalConfirmationModal}
                confirmAction={confirmDeleteAccount}
                label="Confirm Account Deletion"
                description={`The selected admin will now pay for your invited members. Your plan will still be active until the end of the billing cycle. Are you sure you want to delete your account?`}
                confirmText="Confirm"
                cancelText="Cancel"
            />

            <NewPasswordModal
                newPasswordModal={newPasswordModal}
                setNewPasswordModal={setNewPasswordModal}
            />
        </>
    );
}

export default AccountSetting;