import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../store/index";
import { setAuthState } from "../store/authSlice";
import { toast } from "react-toastify";
import apiClient from "../utils/axiosInstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { usePostHog } from "posthog-js/react";
import { useGoogleLogin } from "@react-oauth/google";
import * as Yup from "yup";
import axios from "axios";
import AppleSignin from "react-apple-signin-auth";
import { jwtDecode } from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/logo.png";
import { allowedEmailDomains } from "../utils/static";
import SpinnerLoading from "../Components/SpinnerLoading";

const IndividualPlan = [
  {
    title: "Individual Plan",
    desc: [
      "With our individual plan, gain access to:",
      "Accurate Answers: Get precise information on US public companies, SEC flings, earnings transcripts, market data, and more.",
      "Comprehensive Research: Seamlessly search through web and private documents in a single interface.",
    ],
  },
  {
    title: "Business Plan",
    desc: [
      "Supercharge your team's productivity with business plan.Sign up with your business email and experience:",
      "AI-Enhanced Chat: Use Askfin as your work chat app with an AI financial analyst to answer a wide range of questions from any thread.",
      "Effortless Collaboration: Work seamlessly with colleagues and keep everyone on the same page.",
    ],
  },
];

const SignUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
    .test("is-allowed-domain", "Email domain not allowed", function (value) {
      if (!value) return false;
      const domain = value.split("@")[1];
      return allowedEmailDomains.includes(domain);
    }),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

function Auth() {
  const posthog = usePostHog();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const state = useLocation().state;
  const [plansDropDown, setPlansDropDown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoginMode, setIsLoginMode] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (state?.toLogin) {
      setIsLoginMode(true);
    }
  }, [state?.toLogin]);

  const redirectButton = () => {
    setIsLoginMode(!isLoginMode);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const redirecToAuth = () => {
    navigate("/auth");
  };

  const toggleDropdown = () => {
    setPlansDropDown(!plansDropDown);
  };

  const handleEmailAuth = async (values) => {
    const { email, password } = values;
    try {
      setLoading(true);
      const response = await apiClient().post(
        `${process.env.REACT_APP_API_URL}/${isLoginMode ? "login" : "signup"}`,
        {
          email: email,
          password: password,
        }
      );
      if (response && response.data && isLoginMode) {
        localStorage.setItem("authToken", response.data.token);
        navigate("/dashboard");
        dispatch(setAuthState(true));
      }
      if (response && response.data && !isLoginMode) {
        posthog.capture("signup", { email: email });
        posthog.alias(email);
        navigate("/email/verification", { state: { email: email } });
      }
    } catch (error) {
      toast.error(error);
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const signInWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const googleApiResponse = await axios.get(
          `${process.env.REACT_APP_GOOGLE_API}?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        );

        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/sign-in-with-services`,
          {
            service: "google",
            name: googleApiResponse.data.name,
            email: googleApiResponse.data.email,
          }
        );

        if (result && result.data) {
          dispatch(setAuthState(true));
          if (result.data.isFirstTime) {
            navigate(`/create-profile/${result.data.token}`);
          } else {
            localStorage.setItem("authToken", result.data.token);
            navigate("/dashboard");
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    },
    onError: (error) => {
      console.log("Login Failed:", error);
    },
  });

  const successResponse = async (response) => {
    setLoading(true);
    let data = {};
    try {
      const decodedData = jwtDecode(response.authorization.id_token);

      data = {
        name: response.user ? response.user.name : "",
        email: response.user ? response.user.email : decodedData.email,
      };

      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/sign-in-with-services`,
        {
          service: "apple",
          name: data.name || "",
          email: data.email,
        }
      );

      if (result && result.data) {
        dispatch(setAuthState(true));
        if (result.data.isFirstTime) {
          navigate(`/create-profile/${result.data.token}`);
        } else {
          localStorage.setItem("authToken", result.data.token);
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <SpinnerLoading isLoading={loading} />}
      <div>
        <div className="w-full flex-grow flex justify-center">
          <div className="w-[1200px]">
            <div className="flex items-center justify-between py-6 lg:px-10">
              <div
                className="flex cursor-pointer"
                onClick={() => {
                  navigate("/");
                }}
              >
                <img
                  src={Logo}
                  alt="logo"
                  className="h-8 w-8 mr-2 md:ml-0 ml-2"
                />
                <p className=" text-2xl tracking-widest font-semibold">
                  ASKFIN
                </p>
              </div>
              <div className="hidden md:flex ">
                <button
                  className="md:ml-12 ml-10 text-base font-normal text-landingText"
                  onClick={() => {
                    navigate("/", { state: { toPricing: true } });
                  }}
                >
                  Plans
                </button>
                <button
                  className="md:ml-12 ml-6 text-base font-normal text-landingText"
                  onClick={redirectButton}
                >
                  {isLoginMode ? "Sign Up" : "Sign In"}
                </button>
              </div>
              <div className="md:hidden flex items-center mr-11">
                <button onClick={toggleDropdown} className="focus:outline-none">
                  <Icon icon="ic:round-menu" width="1.5em" height="1.5em" />
                </button>
              </div>
              {plansDropDown && (
                <div className="md:hidden absolute right-5 top-12 mt-2 w-48 bg-white rounded-md shadow-lg">
                  <button
                    className="block px-4 py-2 text-sm font-normal text-landingText hover:bg-borderColor"
                    onClick={() => {
                      navigate("/", { state: { toPricing: true } });
                    }}
                  >
                    Plans
                  </button>
                  <button
                    className="block px-4 py-2 text-sm font-normal text-landingText hover:bg-borderColor"
                    onClick={redirecToAuth}
                  >
                    Sign In
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 border-t border-lendingButton">
          <div
            className="md:col-span-5 bg-lendingButton px-8 text-white"
            style={{ height: "calc(100vh - 81px)" }}
          >
            {IndividualPlan.map((plan, index) => (
              <div key={index}>
                <p
                  key={index}
                  className="text-2xl font-bold pt-16 tracking-wide"
                >
                  {plan.title}
                </p>

                <ul className="list-disc">
                  {plan.desc.map((item, idx) => (
                    <li
                      key={idx}
                      className="text-lg font-normal mt-5 text-white"
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="md:col-span-7 px-4 md:px-0 flex items-center">
            {!isLoginMode ? (
              <>
                <div className="max-w-md mx-auto p-6 bg-white rounded-lg dark:bg-gray-800">
                  <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
                    Create your free account
                  </h2>
                  <Formik
                    initialValues={{
                      email: "",
                    }}
                    validationSchema={SignUpSchema}
                    onSubmit={handleEmailAuth}
                  >
                    {({ errors, touched, isSubmitting }) => (
                      <Form>
                        <div className="mb-6">
                          <label
                            htmlFor="email"
                            className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                          >
                            Work Email
                          </label>
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-lendingButton dark:bg-gray-700 dark:text-white"
                            placeholder="you@example.com"
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="text-red-500 mt-1 text-sm"
                          />
                        </div>
                        <div className="mb-6">
                          <div className="relative">
                            <label
                              htmlFor="email"
                              className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                            >
                              Set a password
                            </label>
                            <Field
                              type={showPassword ? "text" : "password"}
                              id="password"
                              name="password"
                              className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-lendingButton"
                              placeholder="Password"
                            />
                            <span
                              className="absolute right-0 bottom-2 flex items-center pr-4 cursor-pointer"
                              onClick={toggleShowPassword}
                            >
                              <Icon
                                icon={showPassword ? "mdi:eye-off" : "mdi:eye"}
                                className="text-gray-400 w-6 h-6"
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="text-red-500 mt-1 text-sm"
                          />
                        </div>
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="w-full px-4 py-2 text-white font-medium bg-lendingButton rounded-lg hover:bg-lendingButton focus:outline-none focus:ring-2 focus:ring-lendingButton focus:ring-offset-2 transition-colors duration-300 ease-in-out"
                        >
                          Continue
                        </button>
                      </Form>
                    )}
                  </Formik>

                  <>
                    <hr className="my-6 border-gray-300 dark:border-gray-600" />
                    <button
                      onClick={() => signInWithGoogle()}
                      className="flex items-center m-auto justify-center mt-5 p-2 w-full md:w-96 text-gray-900 text-center rounded-full font-normal text-lg bg-allBorderColor hover:bg-lendingButton hover:text-white"
                    >
                      <Icon icon="prime:google" className="text-xl" />
                      Continue with Google
                    </button>
                    <AppleSignin
                      authOptions={{
                        clientId: `${process.env.REACT_APP_APPLE_CLIENT_ID}`,
                        scope: "name email",
                        redirectURI: `${process.env.REACT_APP_APPLE_REDIRECT_URI}`,
                        state: "state",
                        nonce: "nonce",
                        usePopup: true,
                      }}
                      onSuccess={(response) => successResponse(response)}
                      onError={(error) => console.error(error)}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          className="flex items-center m-auto justify-center mt-5 p-2 w-full md:w-96 text-gray-900 text-center rounded-full font-normal text-lg bg-allBorderColor hover:bg-lendingButton hover:text-white"
                        >
                          <Icon icon="prime:apple" className="text-xl" />
                          Continue with Apple
                        </button>
                      )}
                    />
                  </>
                </div>
              </>
            ) : (
              <div className="w-[28rem] mx-auto p-6 bg-white rounded-lg dark:bg-gray-800 mt-8">
                <h2 className="text-2xl font-semibold mb-6 text-gray-800 dark:text-white">
                  Sign In
                </h2>
                <Formik
                  initialValues={{ email: "", password: "" }}
                  validationSchema={Yup.object().shape({
                    email: Yup.string()
                      .email("Invalid email")
                      .required("Email is required"),
                    password: Yup.string().required("Password is required"),
                  })}
                  onSubmit={handleEmailAuth}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="mb-6">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300"
                        >
                          Email address
                        </label>
                        <Field
                          type="email"
                          id="email"
                          name="email"
                          className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-lendingButton dark:bg-gray-700 dark:text-white"
                          placeholder="you@example.com"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 mt-1 text-sm"
                        />
                      </div>
                      <div className="mb-6">
                        <div className="relative">
                          <Field
                            type={showPassword ? "text" : "password"}
                            id="password"
                            name="password"
                            className="w-full px-3 py-2 text-gray-700 bg-gray-100 rounded-lg focus:outline-none focus:ring-2 focus:ring-lendingButton dark:bg-gray-700 dark:text-white"
                            placeholder="Password"
                          />
                          <span
                            className="absolute inset-y-0 right-0 flex items-center pr-4 cursor-pointer"
                            onClick={toggleShowPassword}
                          >
                            <Icon
                              icon={showPassword ? "mdi:eye-off" : "mdi:eye"}
                              className="text-gray-400 w-6 h-6"
                            />
                          </span>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-red-500 mt-1 text-sm"
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full px-4 py-2 text-white font-medium bg-lendingButton rounded-lg hover:bg-lendingButton focus:outline-none focus:ring-2 focus:ring-lendingButton focus:ring-offset-2 transition-colors duration-300 ease-in-out"
                      >
                        Sign In
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
