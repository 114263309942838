// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

// ** Reducers
import { authReducer } from "./authSlice";
import { finChatReducer } from "./finChat";
import { toggleReducer } from "./toggleSlice";
import { teamReducer } from "./teamSlice";
import { organizationReducer } from "./organization";
import { threadReducer } from "./ThreadSlice";
import { sidebarReducer } from "./sidebarSlice";
import { documentSlice } from "./DocumentSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    finChat: finChatReducer,
    toggle: toggleReducer,
    teams: teamReducer,
    organization: organizationReducer,
    thread: threadReducer,
    sidebar: sidebarReducer,
    document: documentSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
