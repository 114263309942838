import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { plans, proPlans } from "../../utils/static";

function Pricing() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("monthly");

  const redirectButton = () => {
    navigate("/dashboard");
  };

  return (
    <>
      <div>
        <div>
          <div
            className="flex justify-center mb-5 mt-10 rounded-md px-4"
            role="group"
          >
            <button
              onClick={() => setActiveTab("monthly")}
              type="button"
              className={`px-4 py-4 text-base font-medium w-44 lg:w-48 ${activeTab === "monthly"
                ? "text-white bg-lendingButton"
                : "text-pricingText bg-[#F5F5F7]"
                } rounded-l-[22px]  `}
            >
              Monthly
            </button>

            <button
              onClick={() => setActiveTab("annual")}
              type="button"
              className={`px-4 py-4 text-base font-medium w-44 lg:w-48 ${activeTab === "annual"
                ? "text-white bg-lendingButton"
                : "text-pricingText bg-[#F5F5F7]"
                } rounded-r-[22px]`}
            >
              Annual
            </button>
          </div>
        </div>

        {activeTab === "monthly" ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-8 gap-4 px-4 md:px-0">
            {plans.map((plan, index) => (
              <div
                key={index}
                className={`p-4 bg-[#F5F5F7] rounded-xl ${plan.shadow}`}
              >
                <div>
                  <div className="flex items-center justify-between">
                    <p className="text-2xl font-normal text-landingText">
                      {plan.name}
                    </p>
                    {plan.isMostPopular && (
                      <button className="text-base font-normal text-lendingButton">
                        Most Popular
                      </button>
                    )}
                  </div>
                  <p className="mt-3 text-[32px] font-bold text-landingText">
                    <span className="tracking-wide">
                      {plan.price}
                    </span>
                    <sub className="text-base font-normal text-[#6B7280] ml-2">
                      {plan.period}
                    </sub>
                  </p>
                  <button
                    className={` w-full border-2 rounded-3xl text-base p-2 ${plan.buttonClass}`}
                    onClick={redirectButton}
                  >
                    Get Started
                  </button>

                  <div className="mt-10">
                    <p className="text-base font-normal text-landingText">
                      What’s included
                    </p>
                    <div>
                      {plan.features.map((feature, idx) => (
                        <p
                          key={idx}
                          className="flex items-start mt-3 text-base font-normal text-pricingText"
                        >
                          <span className="mr-2">
                            <Icon
                              icon="iconoir:check"
                              width="1.2em"
                              height="1.2em"
                              className="text-lendingButton"
                            />
                          </span>
                          {feature}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 px-4 md:px-0">
            {proPlans.map((plan, index) => (
              <div
                key={index}
                className={`p-4 bg-[#F5F5F7] rounded-xl ${plan.shadow}`}
              >
                <div>
                  <div className="flex items-center justify-between">
                    <p className="text-2xl font-normal text-landingText">
                      {plan.name}
                    </p>
                    {plan.isMostPopular && (
                      <button className="text-base font-normal text-lendingButton">
                        Most Popular
                      </button>
                    )}
                  </div>
                  <p className="mt-3 text-[32px] font-bold text-landingText">
                    <span className="tracking-wide">
                      {plan.price}
                    </span>
                    <sub className="text-base font-normal text-[#6B7280] ml-2">
                      {plan.period}
                    </sub>
                  </p>
                  <button
                    className={`w-full border-2 rounded-3xl p-2 text-base ${plan.buttonClass}`}
                    onClick={redirectButton}
                  >
                    Get Started
                  </button>

                  <div className="mt-10">
                    <p className="text-base font-normal text-landingText">
                      What’s included
                    </p>
                    <div>
                      {plan.features.map((feature, idx) => (
                        <p
                          key={idx}
                          className="flex items-start mt-3 text-base font-normal text-pricingText"
                        >
                          <span className="mr-2">
                            <Icon
                              icon="iconoir:check"
                              width="1.2em"
                              height="1.2em"
                              className="text-lendingButton"
                            />
                          </span>
                          {feature}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default Pricing;
