import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import apiClient from "../utils/axiosInstance";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import DashboardSidebar from "./DashboardSidebar";
import { useAppDispatch, useAppSelector } from "../store";
import { getUserDetails } from "../store/authSlice";
import useAuth from "../utils/useAuth";

function Subscription() {
  const [clientSecret, setClientSecret] = useState(null);
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { userDetailsState } = useAppSelector((state) => ({
    userDetailsState: state.auth.userDetails
  }));
  const navigate = useNavigate();

  useEffect(() => {
    const getClientSecret = async () => {
      const customerSession = await apiClient().get("/stripe-customer-secret");

      setClientSecret(customerSession?.data?.clienSecret);
    };
    getClientSecret();
  }, []);

  useEffect(() => {
    if (user && !userDetailsState) {
      dispatch(getUserDetails(user?.userId));
    }
  }, [user]);

  return (
    <div>

      <div className="flex md:flex-row flex-col">
        <DashboardSidebar />
        <Sidebar />
        <div className="p-8 header-height w-full md:px-0 px-2 my-pb-20">
          {(clientSecret && userDetailsState) && (
            <div>
              <div>
                <button onClick={() => navigate("/account")}>
                  <Icon icon="icon-park:left" className="h-7 w-7" />
                </button>
              </div>
              <script
                async
                src="https://js.stripe.com/v3/pricing-table.js"
              ></script>
              <stripe-pricing-table
                pricing-table-id={userDetailsState?.usageType === "business" ? process.env.REACT_APP_STRIPE_TEAM_PRICING_TABLE_ID : process.env.REACT_APP_STRIPE_INDIVIDUAL_PRICING_TABLE_ID}
                customer-session-client-secret={clientSecret}
                publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
              ></stripe-pricing-table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Subscription;
