import Sidebar from "../Components/Sidebar";
import SpinnerLoading from "../Components/SpinnerLoading";
import DashboardSidebar from "./DashboardSidebar";
import { useParams } from "react-router-dom";
import Documents from "../Components/Documents/Documents";
import { useAppDispatch, useAppSelector } from "../store";
import { useEffect } from "react";
import { getFolders } from "../store/DocumentSlice";

function Folder() {
  const { folderId } = useParams();
  const dispatch = useAppDispatch();
  const { folderState } = useAppSelector((state) => ({
    folderState: state.document.folders,
  }));

  useEffect(() => {
    if (folderState.length === 0 || folderState.folders.length === 0) {
      dispatch(getFolders());
    }
  }, []);

  const folder = folderState?.folders?.find((folder) => folder.folderId === folderId);

  return (
    <>
      <SpinnerLoading />
      <div>
        <div className="flex md:flex-row flex-col">
          <DashboardSidebar />
          <Sidebar />
          <div
            className={`bg-backgroundColor header-height flex justify-center items-center w-full md:px-0 px-2 my-pb-20`}
          >
            <div className="rounded-lg w-[100%] md:w-full lg:w-full">
              <div className="relative">
                <div className="header-height overflow-y-hidden lg:h-[100%] xl:h-[100vh] w-full">
                  {folder && <Documents folder={folder} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Folder;
