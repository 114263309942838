import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        expandedTab: null,
        activeTab: null,
        dashboardActiveTab: "Home",
    },
    reducers: {
        setExpandedTab: (state, action) => {
            state.expandedTab = action.payload;
        },
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        dashboardActiveTab: (state, action) => {
            state.dashboardActiveTab = action.payload;
        }
    },
});

export const { setExpandedTab, setActiveTab, dashboardActiveTab } = sidebarSlice.actions;

export const sidebarReducer = sidebarSlice.reducer;