import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../utils/axiosInstance";
import { toast } from "react-toastify";

export const getOrganization = createAsyncThunk("getOrganization", async () => {
    try {
        const response = await apiClient().get(`/organization`);
        return response.data.organization;
    } catch (error) {
        console.log("error in chat slice:", error);
        toast.error(`${error}`);
        throw error;
    }
});

export const organization = createSlice({
    name: "organization",
    initialState: {
        organization: [],
        organizationStatus: null,
    },
    reducers: {
        addMemberToOrganization: (state, action) => {
            state.organization[0].members.push(action.payload);
        },
        removeMemberFromOrganization: (state, action) => {
            state.organization[0].members = state.organization[0].members.filter(
                (member) => member.userId !== action.payload
            );
        },
        updateMemberRole: (state, action) => {
            state.organization[0].members = state.organization[0].members.map((member) => {
                if (member.userId === action.payload.userId) {
                    member.role = action.payload.role;
                }
                return member;
            });
        },
        resetMemberNotification: (state, action) => {
            state.organization[0].members = state.organization[0].members.map((member) => {
                if (member.userId === action.payload.memberId) {
                    member.notification = 0;
                }
                return member;
            });
        },
        updateMemberNotification: (state, action) => {
            state.organization[0].members = state.organization[0].members.map((member) => {
                if (member.userId === action.payload.memberId) {
                    member.notification = member.notification + 1;
                }
                return member;
            });
        },
        updateOrgMemberStatuses: (state, action) => {
            const { email, status } = action.payload;
            state.organization[0].members = state.organization[0].members.map((member) => {
                if (member.email === email) {
                    return { ...member, user_status: status };
                }
                return member;
            });
        },
        updateOrgMember: (state, action) => {
            const updatedMember = action.payload;
            state.organization[0].members = state.organization[0].members.map(member =>
                member.userId === updatedMember.userId ? { ...member, ...updatedMember } : member
            );
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getOrganization.fulfilled, (state, action) => {
                state.organizationStatus = "succeeded";
                state.organization = action.payload;
            })
            .addCase(getOrganization.pending, (state, action) => {
                state.organizationStatus = "loading";
            })
            .addCase(getOrganization.rejected, (state, action) => {
                state.organizationStatus = "failed";
            });
    },
});


export const { addMemberToOrganization, removeMemberFromOrganization, updateMemberRole, resetMemberNotification, updateMemberNotification, updateOrgMemberStatuses, updateOrgMember } = organization.actions;
export const organizationReducer = organization.reducer;
